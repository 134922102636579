:root {
    --color-prime: #007aff;
    --color-prime-hover: #08539e;
    --color-dark-black: #080808;
    /* --bg-body: #f4f4f4; */
    --bg-body: #f4f4f4;
    --borderRadiusSemiRound: 10%;
    --borderRadiusSquare: 0;
    --borderRadiusRounded: 50%;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qNiXg7eU0.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-qNiXg7Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQftx9897sxZ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwftx9897g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  line-height: 1.15;
  font-size: 15px!important;
  letter-spacing: 0rem;
  letter-spacing: normal;
}
body {
    color: #080808;
    font-family: Lato,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-size: 15px; */
    font-size: 14px;
    background-color: var(--bg-body);
  }

  h1 {
    font-size: 2.75rem;
    font-weight: normal;
    color: #080808; 
  }
  
  h2 {
    font-size: 2.25rem;
    font-weight: normal;
    color: #080808;
  }
  
  h3 {
    font-size: 2rem;
    font-weight: normal;
    color: #080808;
  }
  
  h4 {
    font-size: 1.5rem;
    font-weight: normal;
    color: #080808;
  }
  
  h5 {
    font-size: 1.3125rem;
    font-weight: normal;
    color: #080808;
  }
  
  h6 {
    font-size: 1.125rem;
    font-weight: normal;
    color: #080808;
  }
[dir] .modal .modal-header .close{
    box-shadow:none;
}
[dir=ltr] .modal .modal-header {
    border-radius: var(--borderRadiusSquare)
}
.auth-wrapperMain .card .card-title {
    font-size: 1.5rem;
}
  small{
    font-size: .875rem
  }
[dir] .app-content.archiveViewer{
    padding:0rem 1.2rem!important;
  }
[dir] .auth-wrapperMain .form-control {
    padding: 1.5rem 0.5rem;
    font-size: .975rem !important;
    font-weight: 400;
    height: 3rem;
}
[dir] .auth-wrapperMain .form-group {
  margin-bottom: 1rem;
}
[dir] .auth-wrapperMain  .btn{
  padding: 0 2rem;
  line-height: 1.45;
  height: 3rem;
} 

.top-options__text.active_line {
  border-bottom: 0.2rem solid var(--color-prime-hover);
}
#Filter-main-viewPage .row_search.row{
  left:0;
  margin-left: 0;
}
/* for thumb ends */

/* breakpoints */

/* for thumb */
[dir] .ecommerce-application .design_scroll #large_grid.grid-view {

  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 0;
}
[dir] #medium_grid {
  column-gap: 1rem;
  row-gap: 1rem;
 margin-top: 0;
}

html[dir] [data-col="1-column"].horizontal-layout .app-content {
  padding:0rem 1.2rem !important;
}
#root .app-content{
  padding:0rem 1.2rem !important;
}
[dir] #root .blank-page .app-content {
  padding: 0rem!important;
}
.design_scroll {
  margin-top: 0rem;
  padding-top: 1.2rem;
}
[dir] .boards_view.boardsPage, .boards_view.collectionPage{ 
  margin-top: 4rem;
}
.ecommerce-application .grid-view .ecommerce-card {
    overflow: visible;
}

.ecommerce-card.card.cartOpened {
    overflow: visible!important;
}
#_infoTable{
    display:none;
}
#boardswindow .custom-select {
    display:none;
}
#boardswindow .sel_size {
    display: none;
}
.page-item .page-link {
  font-size: .7rem;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
}
/*#_info.openedInfo #_infoTable {
    display: block;
}
#_info.openedInfo.off #_infoTable {
    display: none;
}*/
/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    [dir] .ecommerce-application .design_scroll .grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-auto-rows: minmax(20rem, 1fr)
        /*  grid-template-columns: 1fr 1fr 1fr  ;  */
    }
    div#boardswindow .grid-view {
        grid-template-columns: 1fr ;
    }
    .clientGrid {
        padding: 0 .5rem;
    }
 }

 /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .clientGrid {
        padding: 0 2rem;
    }
    .less_than_four.grid-view {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem)) !important;
    }
    [dir] .ecommerce-application .design_scroll .grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr)) !important;
        grid-auto-rows: minmax(20rem, 1fr) !important;
        /*  grid-template-columns: 1fr 1fr 1fr  ;  */
    }
    [dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
        min-height: 15rem!important;
    }
    div#boardswindow .grid-view {
        grid-template-columns: 1fr 1fr;
    }
  }

 /* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    div#boardswindow .grid-view {
        grid-template-columns: 1fr 1fr 1fr;
    }
        div#boardswindow .grid-view#large_grid, div#boardswindow .grid-view#medium_grid {
            grid-template-columns: 1fr 1fr 1fr;
        }
}

 /* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    [dir] .ecommerce-application .design_scroll .grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));        
        grid-auto-rows: minmax(20rem, 1fr)
        /*  grid-template-columns: 1fr 1fr 1fr  ;  */
    }
    div#boardswindow .grid-view {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    /*    form medium grid-view*/

    [dir] .ecommerce-application .design_scroll #medium_grid.grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-auto-rows: minmax(20rem, 1fr);
    }

    #on_floatfilter .design_scroll #medium_grid.grid-view {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-auto-rows: minmax(15rem, 1fr)
    }

    [dir] .ecommerce-application #medium_grid.grid-view .ecommerce-card .item-img {
        min-height: 15rem;
    }
    /*    form medium grid-view ends*/
 }
@media (max-width: 1440.98px) {
    .less_than_four.grid-view {
        grid-template-columns: repeat(auto-fit, minmax(20rem, 20rem)) !important;
    }
  [dir] .ecommerce-application .design_scroll .grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-auto-rows: minmax(20rem, 1fr);
}
    .auth-wrapperMain .left-banner { 
        background-image: url("../assets/images/collections/archive-bg3.jpg");      
        background-repeat: no-repeat;
        background-position:center center;
    }

    [dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
        min-height:20rem;
    }
    div#boardswindow .grid-view {
        grid-template-columns: 1fr 1fr 1fr 1fr!important;
    }
        div#boardswindow .grid-view#large_grid, div#boardswindow .grid-view#medium_grid {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
}
@media (min-width:1441px){
    .clientGrid {
        padding: 0 12rem;
    }

    .less_than_four.grid-view {
        grid-template-columns: repeat(auto-fit, minmax(20rem, 20rem)) !important;
    }
    [dir] .ecommerce-application .design_scroll .grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-auto-rows: minmax(20rem, 1fr);       
    }


    [dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
        min-height: 20rem;
    }
    /*    form medium grid-view*/


    [dir] .ecommerce-application .design_scroll #medium_grid.grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-auto-rows: minmax(20rem, 1fr);
    }

    #on_floatfilter .design_scroll #medium_grid.grid-view {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-auto-rows: minmax(15rem, 1fr)
    }

    [dir] .ecommerce-application #medium_grid.grid-view .ecommerce-card .item-img {
        min-height: 15rem;
    }
    /*    form medium grid-view ends*/

    div#boardswindow .grid-view {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    }
    div#boardswindow .grid-view#large_grid, div#boardswindow .grid-view#medium_grid {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
 }
}
@media (min-width:1600px) {
    /*for large grid-view*/
   [dir] .ecommerce-application .design_scroll #large_grid.grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-auto-rows: minmax(20rem, 1fr);
    }

    #on_floatfilter .design_scroll #large_grid.grid-view {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        grid-auto-rows: minmax(20rem, 1fr)
    }
 
    [dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
        min-height: 20rem;
    }

/*    form medium grid-view*/

    [dir] .ecommerce-application .design_scroll #medium_grid.grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-auto-rows: minmax(15rem, 1fr);
    }

    #on_floatfilter .design_scroll #medium_grid.grid-view {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-auto-rows: minmax(15rem, 1fr)
    }

    [dir] .ecommerce-application #medium_grid.grid-view .ecommerce-card .item-img {
        min-height: 15rem;
    }
    /*    form medium grid-view ends*/

    div#boardswindow .grid-view {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  div#boardswindow .grid-view#large_grid, div#boardswindow .grid-view#medium_grid {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
 }
.less_than_four.grid-view {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 25rem))!important;
    }
}