:root{
    --color-prime:#007aff;
    --color-prime-hover:#08539e;
    --color-dark-black:#080808;
    --color-medium-grey:#747474;
    --color-light-grey:#dadce0;    
  }
.bordered-layout .pinkbg svg {
    color: #000;
}

.bordered-layout .sidebar {
    background: #fff;
}

.bordered-layout .sidebar-shop {
    background: #fff;
    border-right: 1px solid #ccc;
}

.bordered-layout .ecommerce-header.row_search_b {
    border-bottom: 1px solid #ccc;
    background: #fff;
}

.bordered-layout.dropdown-menu a {
    display: flex;
    align-items: center;
}

.bordered-layout #fullview_back_page {
    background: #fff;
}

.bordered-layout #fullview_back_page_b {
    background: #fff;
    display: block;
    right: 0 !important;
    width: 50px;
    height: 50px;
    right: 0;
}

.bordered-layout .bg-light {
    background-color: #f6f6f6 !important;
}

.bordered-layout .mysearch_head {
    background: #e3e3e3 !important;
}

[dir] .bordered-layout .custom-control-input:not(:checked) ~ .custom-control-label::before {
    background-color: #fff;
    border-color: #0a66c2;
    border: 2px solid #0a66c2;
}

[dir] .bordered-layout .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #0a66c2;
}

[dir] .bordered-layout .pinkbg .header-navbar {
    background: #fff;
    border-bottom: 1px solid #dadce0;
}

[dir] .bordered-layout .wrapper .header-navbar {
    background-color: #fff !important;
    border-bottom: 1px solid #dadce0;
}

[dir] .bordered-layout swal2-title {
    color: #000 !important;
}

[dir] .bordered-layout #vieworderA .modal-body {
    background-color: #fff;
}


[dir] .bordered-layout .ButtonGroup.groupfixed {
    background: #f6f6f6;
}

[dir] .bordered-layout .icon-wrap button.iconthumb.btn-wishlist.iconstate.active {
    background-color: #0a66c2 !important;
    border-radius: var(--borderRadiusSemiRound);
    width: 2.2rem;
    height: 2.2rem;
    padding: .35rem;
    color: #fff !important;
    border: 1px solid #fff0;
}

[dir] .bordered-layout .book_active.active {
    background-color: #0a66c2;
}

[dir] .bordered-layout .valuelabel {
    position: absolute;
     right: 2.9rem;
    color: #000;
    top: .4rem;
}

[dir] .bordered-layout .showoverlayQty {
    color: #000;
}

[dir] .bordered-layout .search-product.form-control::placeholder {
    color: var(--color-medium-grey) !important;
}

[dir] .bordered-layout #boardsmenu svg {
    background: #f4f2f2;
    overflow: visible;
}

[dir] .bordered-layout .topIcon:hover svg {
    background: #0a66c2 !important;
    color: #fff !important;
}

[dir] .bordered-layout .child .card-body .btn:hover {
    background-color: #0a66c2 !important;
    color: #fff;
    border: 0;
    outline: none;
    border: 1px solid #0a66c2 !important;
}
[dir] .bordered-layout .banner_branding {
/*    background: #f7f7f7;*/
}
[dir] .bordered-layout .topFavIcon { 
    background: #f4f2f2 !important;
}
.dthemeCard{
    overflow: hidden;
    height: calc(100vh - 3.8rem)!important;
    margin-bottom: 0;
}
[dir] .bordered-layout #Filter-main-viewPage .row_search{
    border-bottom: 1px solid var(--color-light-grey);    
}
