:root {
    --color-prime: #007aff;
    --color-prime-hover: #08539e;
    --color-dark-black: #080808;
    --color-light-grey: #dadce0;
    --color-white: #fff;
    --color-primeB: #0a66c2;
}

html[dir] body {
    background-color: #f4f4f4;
    overflow: hidden;
}

html .content {
    min-height: calc(100% - 0.5rem);
}

page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
}

    page[size="A4"][layout="portrait"] {
        width: 29.7cm;
        height: 21cm;
    }

div#Filter-fullview-viewPage .content-header {
    margin-top: 0;
}

.content-body .content-header {
    margin-top: 1.5rem;
}

[dir] .btn-primary:hover {
    background-color: var(--color-prime-hover) !important;
    border-color: var(--color-prime-hover) !important;
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 30%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-93 {
    width: 93%;
    width: calc(100% - 1rem);
}

.w-92 {
    width: 92%;
}

.w-94 {
    width: 94%;
}

.w-95 {
    width: 95%;
}

.snackmenu {
    display: none;
}

[dir] .horizontal-menu .navbar.header-navbar .navbar-container {
    padding: 0.8rem 0.8rem !important;
}

[dir] .ecommerce-application .ecommerce-searchbar .input-group {
    box-shadow: none !important;
    border-radius: 0 !important;
    border: .1rem solid #000 !important;
    padding-left: 3rem;
    background-color: #fff;
}

.mobile-filter-text {
    font-size: .875rem;
    width: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select__menu {
    margin-top: -0rem;
    padding-top: 0;
    background: #fff;
    color: #121111;
}

    .select__menu div {
        padding: 0.438rem 1rem;
        background: #fff;
        color: #121111;
    }

    .select__menu option {
        background: #fff;
        color: #121111;
    }

.row_search.row {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    position: fixed;
    width: 100%;
    top: 3.9rem;
    z-index: 8;
}

.row_order.row {
    margin-left: 0;
    margin-right: 0;
    position: fixed;
    width: 100%;
    top: 0rem;
    z-index: 8;
    left: 0;
    padding: 0 1.4rem;
    background: #fff;
}

.row_order .order_head {
    background: #fff;
    padding: 0.6rem 0rem!important;
}

/* .checkout-options {
    margin-top: 3rem;
} */

    .checkout-options .ecommerce-card {
        display: grid;
        grid-template-columns: 0.6fr 2fr 1fr;
    }

.sidebar {
    position: fixed;
    z-index: 998;
    top: 8.5rem;
    max-width: 0;
    padding-left: 1.2rem;
    left: 0;
}

.collezioni_pages .sidebar {
    padding-left: 0rem;
}

div#Filter-fullview-viewPage {
    /* margin-top: -8.3rem; */
    margin-left: -1.2rem;
    margin-right: -1.2rem;
}

.ecommerce-application .sidebar-shop {
    width: 18.6rem !important;
    z-index: 14;
    height: calc(100vh - 8.5rem);
    margin-top: 0 !important;
}

.media-left {
    width: 8rem;
    min-height: 4rem;
    vertical-align: middle;
    height: auto;
    line-height: 64px;
    padding: .5rem 0.2rem;
    vertical-align: middle;
    height: auto;
    padding-bottom: 0;
}

    .media-left img {
        width: 100%;
        vertical-align: middle;
        height: auto;
    }

.mediaprofile {
    cursor: pointer;
    padding-bottom: .5em;
    margin: .5rem;
    border-radius: 0px;
 /*   width: 14rem;
    height: 14rem;*/
    border: 1px solid #101010;
    background: #fff;
    justify-content: center
}

    .mediaprofile:hover {
        background: #e7e5e5;
        cursor: pointer;
    }
/*combine changes form searchhead depend upon eachother 1.2rem*/
[dir=ltr] .navbar-floating .header-navbar-shadow {
    background: none;
}

.horizontal-menu .horizontal-menu-wrapper {
    display: none;
}
/* today */
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
    width: 16rem;
    top: 2.9rem !important;
    padding: 0 !important;
}


.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-logo img {
    height: 3.2rem;
    width: auto !important;
    max-width: auto !important;
}

[dir=ltr] .horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}

[dir=ltr] .vertical-overlay-menu .navbar .navbar-header {
    float: left;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    width: auto;
}


[dir] .card {
    margin-bottom: 1.2rem;
}

[dir] hr {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.dropdown-menu .dropdown-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.4rem 1.28rem;
}

    .dropdown-menu .dropdown-item a {
        display: flex;
        align-items: center;
    }
/* today */
.dropdown-item svg {
    /* width: 2.2rem !important; */
    height: 2.2rem !important;
    margin: 0rem !important;
    margin-right: .5rem !important;
}

.msg-error {
    color: orangered;
}

.filled-star, .unfilled-star {
    width: 16px;
    height: 16px;
}

[dir] .card {
    border-radius: var(--borderRadiusSquare);
}

[dir] .card-body {
    padding: .8rem;
}

[dir] .ecommerce-application .grid-view .ecommerce-card .card-body {
    padding: .6rem !important;
}

.ecommerce-application .ecommerce-card .item-rating svg, .ecommerce-application .ecommerce-card .item-rating i {
    height: .8rem !important;
    width: .8rem !important;
}

input.input_custom.form-control, #product-categories .input_custom, #selectoption button {
    width: 140px !important;
    float: right !important;
    margin-top: 0px !important;
    /* margin-bottom: 10px !important; */
    border-radius: 0rem;
    min-width: 140px;
    cursor: pointer;
    text-transform: capitalize;
    margin-bottom: .2rem;
}

.select__control {
    border-radius: 0 !important;
    border: 1px solid #d8d6de;
    cursor: pointer;
}

.sidebar-shop.show {
    z-index: 999 !important;
    height: 100vh !important;
    background: #fff;
    margin-top: 0;
}

[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
    width: 100%;
    background-repeat: repeat;
    /*  min-height: 16.85rem !important;*/
}

[dir] .ecommerce-application .ecommerce-card:hover {
    cursor: pointer !important;
    border: 1px solid #5c5c5c !important;
}

.ecommerce-application .list-view .ecommerce-card .item-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-repeat: repeat;
    min-height: 16.85rem !important;
}

#viewtable .list-view .ecommerce-card:hover {
    background: #f3f2f7;
    transition: none;
}

.collectionPage #viewtable {
    margin-top: 3.54rem;
}

#product-categories label {
    margin-right: .5rem;
    text-align: right;
    font-weight: 600;
    padding-top: 11px;
}

.noUi-horizontal {
    height: 4px;
    width: 95%;
}

.icon-wrap {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 7;
    display: flex;
}

.item-rating {
    position: absolute;
    bottom: 14px;
}
/*button.iconstate.active {
    background: #ef2853!important;
}*/
.ecommerce-application .search-product, .ecommerce-application .input-group-text {
    height: 2.5rem !important;
    /* height: 36px!important; */
}

option.s_option {
    height: 2.714rem !important;
    padding: 0.438rem 1rem !important;
    -webkit-appearance: none !important;
}

.bottom_products .grid-view {
    grid-template-columns: 1fr !important;
}

.swiper-slide {
    text-align: left !important;
    background: none !important;
    padding: 0 !important;
}

.favhover.card {
    padding: .5rem;
    position: absolute;
    right: 36px;
    height: 44px;
    /* right:77px; */
    top: 0px;
    padding-right: 4px !important;
    width: 172px;
    padding-bottom: 6px;
    padding-top: 3px;
    border: 1px solid #ccc;
    background: #fff;
    /* background: #121111; */
    border-radius: 4px !important;
    display: none;
    z-index: -1;
    border-radius: 5px px !important;
}

button.iconthumb .triangle {
    display: none;
    top: 33px;
}

button.iconthumb.btn-wishlist:hover .triangle, .favhover .triangle {
    display: block;
    transform: rotate(90deg);
    border-width: 0px 9px 9px 9.3px;
    right: -12px;
    top: 10px
}

button.iconthumb.btn-wishlist.active:hover .triangle {
    display: none;
}

.iconthumb.iconstate.active > .triangle {
    display: none;
}

.icon-wrap:hover .favhover {
    display: block;
}

.icon-wrap .morevertical.right:hover .favhover {
    display: none;
}


.favhover:hover .triangle {
    display: block;
}

.successboard .toast-title {
    position: absolute;
    top: 50%;
    left: 80px;
}

.toastify-header .designSwatch {
    width: 60px;
    height: 60px;
}

.favhover svg {
    right: 4px;
    top: 4px;
}

.favhover small {
    font-size: .75rem;
}

.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 8px 8px 8.3px;
    border-color: transparent transparent #fff transparent;
    right: 21px;
    top: -8px;
    border-radius: 13px;
    position: absolute;
}

.boardn {
    font-size: 5rem !important;
    top: 8px;
}

    .boardn small {
        font-size: .9rem;
        position: absolute;
        top: 20px;
        height: auto;
        color: #0a66c2;
        position: absolute;
        padding: 0.2rem 0.5rem;
        border-radius: 0.2rem;
        left: 5px;
    }

.favhover small:nth-child(2) {
    position: absolute;
    top: 5px;
}

button.iconthumb.btn-wishlist:hover .favhover {
    display: flex !important;
}

[dir=ltr] .pagination {
    padding-left: 0;
    justify-content: center;
}

.iconthumb {
    padding: 5px;
    margin-bottom: 4px;
    border: 0;
    background: #5c5c5cbd;
    /* background: #121111a1; */
    color: #fff;
    border: 0;
    font-size: .8rem;
    border: 1px solid #5c5c5c00;
    width: 2.2rem;
    height: 2.2rem;
}

    .iconthumb svg {
        margin: 0 !important;
    }

.header-navbar {
    min-height: 4rem;
    max-height: 4rem;
}

#fullview_back_page.header-navbar {
    min-height: 4rem;
    justify-content: left;
}

#fullview_back_page .navbar-container {
    padding: 0.5rem 1rem;
}

.horizontal-menu .navbar.header-navbar .navbar-container {
    padding: 0.8rem 2rem;
    /* min-height: 62px; */
    min-height: 4rem;
    height: 4rem;
}

.mysearch_head.col {
    min-height: 4.5rem;
    max-height: 4.5rem;
    padding: .8rem;
}

.cursor {
    cursor: pointer
}

.chat-symbol {
    position: absolute;
    right: 6px;
    bottom: 0;
}

.chatpopup {
    height: 100%;
    display: flex;
    display: inline;
    width: 100%;
    background: #dddddd;
}

.designpic img {
    width: 100%;
}

.chatpopup .chat-widget .chat-app-window .user-chats {
    height: calc(100vh - 110px);
}

.designpic {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    text-align: center;
}

.chat-app-window {
    height: 100%;
    /*    float: left;
    width: 25%;*/
    background: whitesmoke;
}

.child .card:hover {
    border: 1px solid #4c4b4b;
    cursor: pointer;
}

[dir] .bordered-layout .header-navbar.fixed-top {
    border-bottom: 1px solid #ebe9f1;
    background: #ffffff;
}

.boardtable tr td {
    /* height: 7rem; */
    padding: 1rem 0.5rem !important;
    border: none !important;
    text-align: center;
}

.boardtable tr th {
    padding: 1rem 0.5rem !important;
    border: none !important;
    text-align: center;
    white-space: nowrap;
}


#_sliderDiv {
    z-index: 1 !important;
}

.selected_status {
    margin-top: -2rem;
}

.dropdown-menu[x-placement='bottom-end'] {
    margin-top: 2.9rem;
}

div#_zoomImage1Parent {
    overflow: hidden !important;
}

.sidebar .scrollbar-container {
    max-height: calc(100vh - 16.5rem);
    height: auto !important;
    margin-bottom: 1.5rem;
    padding-right: 1rem;
}

div#searchBtn {
    position: static;
    /* filter change above absolute to static */
    bottom: 0;
    width: calc(100% - 1.5rem);
    margin-top: 1rem;
}

.activeboard, .activecollection {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    z-index: 9;
    display: none;
    font-size: .8rem;
}

.ecommerce-card.card:hover .activeboard {
    display: block;
}

.vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu {
    top: 0;
}

#mainZoomdiv .icon-wrap {
    display: none !important;
}

.background-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: rgba(8,8,8,.2);
    transition: opacity .3s ease;
    pointer-events: none;
    bottom: 0;
    top: 0;
    display: none;
}

.list-group-item {
    cursor: pointer;
}

[dir] .ecommerce-application .ecommerce-card:hover .background-overlay {
    display: block;
}

#commentwindow {
    width: 50vw;
    height: 100vh;
    max-width: 75vw;
    max-height: 100vh;
    left: 50%;
}

.commentmodal {
    max-width: 50vw;
    max-height: 100vh;
}

.ecommerce-application .ecommerce-card:focus-visible {
    outline: none
}

[dir] .ecommerce-application .search-product, [dir] .ecommerce-application .input-group-text {
    padding-right: 2rem;
}

#Filter-main-viewPage .S_button {
    padding-right: 1.3rem;
    background: #5c5c5c;
    color: #fff;
}

[dir] #Filter-main-viewPage .S_button:hover {
    background: #101010;
    cursor: pointer;
}
/* new css  starts*/

[dir] .form-control:hover {
    border: 1px solid #080808;
    cursor: pointer;
}

.auth-wrapperMain .left-banner {
    display: flex;
    flex-direction: column;
    background-color: #263a53;
    background-image: url("../assets/images/collections/archive-bg3.jpg");
    /*background-image: url(https://www.gettyimages.in/sign-in/assets/static/886771796-desktop-2e223b4aaf1a33696166.jpg);*/
    /* background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%); */
    width: 100%;
    background-size:cover;
    background-repeat:no-repeat;
}

.auth-wrapperMain .input-group-text {
    position: absolute;
    right: 2%;
    top: 23%;
    border: none !important;
    z-index: 9;
}

.auth-wrapper.auth-v1 {
    justify-content: start !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    flex: 1 1 auto;
    max-width: 415px;
    padding: 3.5rem 2.75rem 4rem;
    text-align: center;
    border: none;
}

.auth-inner .card {
    border: none !important;
}

.auth-wrapperMain {
    display: flex;
    justify-content: end;
}

.parent {
    position: relative;
}

.heroImage {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
}

.top-options__picker {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3.5rem;
}

.top-options__text {
    display: block;
    cursor: pointer;
}

    .top-options__text:not(:first-child) {
        margin-left: 3.5rem;
    }

    .top-options__text:hover {
        color: #0a66c2;
    }

.parentActive.form-label {
    font-size: 12px;
}

.parentActive.form-group {
    position: relative;
}

.parentActive ::placeholder {
    color: #6c757d;
    font-size: 10px;
    position: absolute;
    top: 10px;
    transform: translate3d(10px);
    opacity: 0;
}

.parent .form-label.placeholder {
    position: absolute;
    bottom: 15px;
    font-size: .8rem;
    color: #6c757d;
    left: 1rem;
    display: none;
    left: .6rem;
}

.parentActive .form-label.placeholder {
    position: absolute;
    bottom: 31px;
    font-size: .7rem;
    color: #a6a6a6;
    left: .6rem;
    display: flex;
    z-index: 9;
}

[dir="ltr"] .input-group-append {
    margin-left: 0px;
}

.auth-wrapper .auth-inner {
    position: relative;
    margin-top: 3.5rem;
}

.auth-login-form {
    margin: 2rem 0rem;
}

.login-header {
    padding-bottom: 1.25rem;
}
/* new css ends*/

[dir] .form-control {
    padding: 0.438rem 0.5rem;
    font-size: .875rem !important;
    font-weight: 400;
}

.price-slider.input_custom input {
    width: 65px !important;
    margin-right: 0.7rem;
}

#Filter-main-viewPage .ps__rail-x {
    display: none !important;
}

[dir] .noUi-target {
    padding-right: 0.5rem;
}

[dir=ltr] .noUi-horizontal .noUi-tooltip {
    left: -52% !important;
    font-size: .65rem;
}

[dir] .sidebar-shop .card-body {
    padding-right: 0.2rem;
    /* padding-left:0!important;theme */
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: #010101 !important;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-weight: 400;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: .88rem !important;
}

[dir=ltr] .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0 !important;
    width: 14px;
    height: 22px;
}

.p-multiselect {
    transition: none !important;
    box-shadow: none !important;
    border: 1px solid #a6a6a6 !important;
    height: 2.714rem;
}

.pi-chevron-down:before {
    content: "\e902";
    font-size: 9px;
    color: #d3d3d3;
}

.p-component {
    font-size: 0.975rem;
}

.view-options select {
    min-width: 7rem !important;
}

.p-multiselect-footer .py-2.px-3 {
    padding: 0.5rem 0.5rem !important;
}
/* .iconthumb:hover {
    background: #ffffff;
    color: #000;
    border: 1px solid #ccc!important;
} */

div#boardswindow {
    margin-left: 0 !important;
    margin-top: 4.6rem;
}

[dir=ltr] div#boardswindow .navbar-toggler {
    display: none;
}

.row_search.row:nth-child(2) {
    top: 117px;
    z-index: 99;
}

div#boardswindow .ecommerce-header {
    display: block;
}

    div#boardswindow .ecommerce-header.row_search_b {
        margin-left: 0 !important;
        width: 100%;
    }
/* // Changes By Suresh */

/* div#boardswindow {
    margin-top: 2.5rem;
}  // Changes By Suresh */
.mysearch_head {
    /* background: #fff; */
}

.mysearch_head {
    background: #e3e3e3 !important;
}

button.iconthumb.btn-wishlist.iconstate {
    display: none;
}

    button.iconthumb.btn-wishlist.iconstate.active {
        display: block;
        color: #fff;
        font-weight: 600;
    }

[dir] .ecommerce-application .ecommerce-card:hover .card-body .infoview {
    display: flex !important;
    top: -7.5rem;
}

.infoview {
    display: none !important;
}

[dir] .ecommerce-application .ecommerce-card:hover button.iconthumb {
    display: block;
    margin-bottom: 12px;
    padding: 5px;
    border-radius: var(--borderRadiusSemiRound);
}

.icon-wrap:hover .iconthumb {
    background: #ffffff;
    color: #000;
    border-radius: 50%;
}

button.iconthumb span {
    font-weight: 600;
    margin-right: 6px;
    line-height: 2px;
}

[dir] .icon-wrap:hover .iconthumb {
    border: 1px solid #fff;
}

#boardswindow .design_scroll .icon-wrap .iconthumb:first-child {
    /*display: none; commented by : Vijay*/
}
/* New for sliding added by manisha */
#off_floatfilter .content-body {
    margin-left: 0 !important;
    margin-top: 11.8rem;
    padding: 0 1.2rem;
}

#on_floatfilter .content-body {
    margin-top: 11.8rem;
    padding: 0 1.2rem;
}

body .content-right {
    width: calc(100vw - (100vw - 100%)) !important;
}

div#off_floatfilter .sidebar-left {
    display: none;
    transition: left .3s ease;
}

div#on_floatfilter .sidebar-left {
    display: block;
    transition: left .3s ease;
}

.ecommerce-header.row_search_b {
    position: fixed;
    width: calc(100% - (260px + 1.2rem));
    z-index: 8;
    top: 8.45rem;
    padding: 0rem;
    margin-left: calc(260px + 1.2rem);
    height: 3.5rem;
    left: 0;
}
#searchPage #searchPageInner .ecommerce-header.row_search_b{
    margin-left:0!important;
}

div#off_floatfilter .ecommerce-header.row_search_b {
    margin-left: 0 !important;
    width: calc(100%);
}

div#off_floatfilter .toggle_filter:hover {
    background: #5c5c5c;
    color: #fff;
}

    div#off_floatfilter .toggle_filter:hover .filter-ico {
        fill: #fff;
    }

body.bordered-layout #on_floatfilter .toggle_filter, body.bordered-layout #on_floatfilter .toggle_filter {
    background: #5c5c5c;
    color: #fff;
}

div#on_floatfilter .toggle_filter {
    display: block;
    /* width: 278px; */
    width: 19.8rem;
    padding: 1.2rem;
    padding: 1.2rem;
    position: relative;
    height: 4.5rem;
    align-items: center !important;
    cursor: pointer;
}

body.bordered-layout div#on_floatfilter body.bordered-layout .toggle_filter {
    background: #5c5c5c;
    color: #fff;
}

body.dark-layout div#on_floatfilter body.dark-layout .toggle_filter {
    /* background-color:inherit;
    color:auto; */
}

div#off_floatfilter .toggle_filter {
    padding: 1.2rem;
    position: relative;
    left: 0;
    top: 0;
    display: block;
    width: auto;
    background: #fff;
    color: var(--color-dark-black); /* border: 1px solid var(--color-dark-black); */
    padding-right: 2.8rem;
    align-items: center !important;
    cursor: pointer;
}

#Filter-main-viewPage .row_search {
    border-bottom: 1px solid var(--color-light-grey);
    z-index: 9;
}

[dir=ltr] div#off_floatfilter .toggle_filter .goleft {
    display: none;
}

#on_floatfilter .toggle_filter svg {
    /* fill: #fff; */
}

.goleft {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
}

.goright {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    display: none;
}

[dir=ltr] div#on_floatfilter .toggle_filter .goright {
    display: none;
}

#on_floatfilter .filter-ico {
    fill: #fff;
}

#boardswindow .toggle_filter {
    display: none !important;
}

div#boardswindow .content-body {
    margin-left: 0rem !important;
}

.content .body-content-overlay.show {
    display: none;
}

result-toggler {
    margin-left: 15px;
}

/* #on_floatfilter .ecommerce-application .grid-view {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

#off_floatfilter .ecommerce-application .grid-view {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}Mani */

[dir] .p-inputtext {
    border-radius: 0 !important;
    ;
}

[dir] .p-button {
    background: #ffffff !important;
    border: 1px solid #d8d6de !important;
    border-radius: 0 !important;
    padding: 0.438rem 0.5rem !important;
    color: #010101;
    border-left: none !important;
}

    [dir] .p-button:enabled:hover, [dir] .p-button:not(button):not(a):not(.p-disabled):hover {
        background: #0a66c2 !important;
        ;
        border-color: #0a66c2 !important;
        ;
        padding: 0.438rem 0.5rem !important;
        color: #fff;
    }

.bn_name {
    font-size: 1.1rem;
    color: #0a66c2;
    display: block;
    display: none;
}

#boardswindow .bn_name {
    display: block;
}

.bn_name b {
    border-right: 1px solid #878787;
    padding-right: 1rem;
    font-size: .85rem;
}

.search-results {
    font-size: 1rem;
    font-weight: 400 !important;   
    color: #0b0b0b;
    padding: 1rem;
    position: absolute;
    background: #fff;
    height: 3.4rem;
    display: flex;
    align-items: center;
}

#off_floatfilter .search-results {
    left: 0rem;
}

.fabric_avail {
    position: absolute;
    /* top: -6px; */
    bottom: -39px;
    right: 5px;
}

.fabric_avail_icon img {
    width: 76%;
    padding: 2px;
}

.fabric_avail_icon caption {
    background: #282828;
    width: 100%;
    padding: 3px 0px;
    text-align: center;
    color: #fff;
    display: block;
    position: absolute;
    bottom: 0;
    text-align: center;
    font-weight: 600;
    font-size: 5.5px;
}

.fabric_avail_icon {
    background: #fff;
    overflow: hidden;
    border: 1px solid #fff;
    padding: 0;
    margin: 0px;
    width: 2rem;
    height: 2.5rem;
    color: #000;
    /* background: #000; */
    position: relative;
}

[dir=ltr] .navbar-container .search-input.open {
    right: 0;
}

[dir] .navbar-container .search-input.open {
    background: #ffffff;
    border-radius: 0;
    border-bottom: 1px solid #010101;
    padding: 0;
    display: block;
    width: 350px;
}

.board_collection_box .toggle_filter {
    display: none !important;
}

div#fullview_back_page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

div#fullview_back_page_b {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.search_button.input-group-append {
    position: absolute !important;
    z-index: 9 !important;
    right: 1px !important;
}

span.search_button {
    position: absolute !important;
    left: 0 !important;
    z-index: 9;
}

.navbar-container .search-input .search-input-close {
    padding: 0.5rem 1rem;
    position: absolute !important;
    right: 0 !important;
    z-index: 99;
    top: 0 !important;
    width: 42px !important;
    height: 36px !important;
}

.search_button .input-group-text {
    /* height: 36px !important; */
    padding-right: 1rem !important;
}

.search-input-close {
    position: absolute;
    right: 50px;
    top: 5px;
}

    .search-input-close svg {
        width: 16px;
    }

[dir] .input-group:not(.bootstrap-touchspin):focus-within {
    box-shadow: none !important;
    border: 1px solid #d8d6de !important;
    border-radius: 0rem;
}

span.logo_brand_text {
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
}

img.media-object {
    border: 2px solid #fff;
    /*   outline: 1px solid #ebe9f1;*/
}


.cursor.media-left:hover small {
    color: var(--color-prime-hover);
}

.avatar {
    outline: 1px solid #ebe9f1;
    background-color: none !important;
    background: none !important;
    border: none !important;
}

    .avatar:hover {
        outline: 1px solid #4c4b4b;
    }

.nav-link:hover {
    color: var(--color-prime-hover);
}

    .nav-link:hover svg {
        color: var(--color-prime-hover);
    }

.listname.form-group {
    border: 1px solid #d8d6de;
}

[dir] .btn-secondary:hover {
    background-color: #6f7378 !important;
    border-color: #696d71 !important;
    box-shadow: none !important;
}

input.wishnameVertical {
    padding-left: 3.5rem;
}

.searchlist {
    position: absolute;
    left: 14px;
    top: 8px;
    opacity: 1;
    stroke: var(--color-dark-black);
}

.lettered-avatar-wrapper {
    width: 70px !important;
    height: 70px !important;
    line-height: 70px !important;
    border-radius: 70px !important;
    border: 1px solid #0000;
}

    .lettered-avatar-wrapper:hover {
        border: 1px solid #010101;
    }

.noUi-horizontal .noUi-handle {
    top: -5px !important;
}

[dir] .noUi-tooltip {
    background: none !important;
}

.noUi-horizontal {
    height: 2px !important;
}

div#ecommerce-searchbar select.form-control {
    border: 1px solid #5c5c5c;
}

.filterheading .text-success {
    background: #efefef;
    color: #000 !important;
    padding: 6px 10px;
    font-size: 10px;
    position: absolute;
    top: -50px;
    left: 13rem;
    border-radius: 4px;
    white-space: nowrap;
}

    .filterheading .text-success:hover {
        background: #838383;
        color: #fff !important;
    }

[dir] .header-navbar .navbar-container ul.navbar-nav li > a.nav-link:hover {
    color: #0a66c2;
}
/* li.nav-item span.align-middle:hover{
    color: var(--color-white);
} */
/* datecss */
.p-datepicker table {
    font-size: .8rem !important;
}

[dir] .p-datepicker table td {
    padding: 0.2rem !important;
}

.p-datepicker table td > span {
    width: 1.8rem !important;
    height: 1.8rem !important;
    transition: box-shadow 0.2s;
}

.p-datepicker {
    left: -87px !important;
}

[dir] .p-datepicker:not(.p-datepicker-inline) {
    border-radius: 0 !important;
}

span.propcolon {
    width: 15px;
    display: block;
}

span.cursor.filedownload {
    background: #666;
    border-radius: 100%;
    width: 28px;
    padding: 1px;
    height: 28px;
    background: #f8f8f8;
    margin-right: 6px;
    text-align: center;
    vertical-align: middle;
}

    span.cursor.filedownload:hover {
        background: #d9dde1;
    }

    span.cursor.filedownload svg {
        margin-right: 0 !important;
    }

.selected_colorway img {
    cursor: pointer;
}

.FullviewdesignSwatch span img {
    cursor: pointer;
}

td.board_col_remove svg {
    background: #fbf7f7;
    width: 30px;
    height: 30px;
    padding: 8px;
}

    td.board_col_remove svg:hover {
        background: #82868b;
        color: #fff;
    }

.collectionclick {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: #6660;
    top: 0;
}


#vieworderA .option-hover {
    display: none;
}

#createWindow .reportbtn {
    display: none;
}

#vieworder .orderbtn {
    display: none;
}

#vieworder button.close :hover {
    transform: none;
    opacity:1
}

#createWindow button.close :hover {
    transform: none;
    opacity:1
}

#Filter-main-viewPage .sidebar .scrollbar-container {
    max-height: calc(100vh - 13.5rem);
    height:auto !important
}

div#Filter-main-viewPage hr {
    border-top: 1px solid #c1c1c1;
    margin-top: 0.5rem;
}

.avtaar_bg {
    display: flex;
    background: #ededed;
    padding: 1rem;
}

[dir] .avatar img {
    box-shadow: none !important;
}

.user-status {
    font-size: .87rem;
}

.mobile_Menu.dropdown {
    display: none;
}

li.desktop_dropdown .dropdown-menu {
    right: -1.5rem !important;
}

.mobile_Menu.dropdown .dropdown-menu {
}

.show_mobile {
    display: none;
}

[dir="ltr"] #notificationMenu .media-list .media, [dir="ltr"] #notificationMenuB .media-list .media {
    padding: 0.4rem 1.28rem;
}

[dir="ltr"] #notificationMenu .media .media-left, [dir="ltr"] #notificationMenuB .media .media-left {
    min-height: auto;
    line-height: 23px;
    width: 45px !important;
    height: 30px !important;
    text-align: center;
    padding: .5rem;
    text-align: center;
}

[dir="ltr"] #notificationMenu .avatar-content, [dir="ltr"] #notificationMenuB .avatar-content {
    width: 50px;
    height: 50px;
    line-height: auto;
}

.media-body .badge.badge-up {
    right: 0;
    top: 50%;
    transform: translate(-50%,-50%);
}

.media-body {
    position: relative;
}

#createWindow.modal-dialog, #vieworderA.modal-dialog {
    height: 100vh;
}

#vieworderA.modal-dialog {
    height: 100vh;
    background: #fff;
}

#createWindow .modal-content, #vieworderA .modal-content {
    height: 100%;
    box-shadow: none !important;
}

.pinkbg hr {
    width: 45%;
}

#collection_Window .boardCheck {
    display: none;
}

#collection_Window .selectThumbnails {
    display: none;
}

#collection_Window .hideForColl {
    display: none;
}
/*26-09*/
.dark-layout .select__control .select__placeholder {
    color: #fff !important;
}

[dir] .dark-layout .card {
    border: 1px solid grey;
}

    [dir] .dark-layout .card:hover {
        border: 2px solid #fff;
    }

[dir] .child .card:hover {
    border: 1px solid #4c4b4b;
}


/*common in both*/
/* swal popup */


.swal2-styled:focus {
    box-shadow: none;
}

h2#swal2-title {
    font-size: 1.5rem;
    color: #000;
}

.swal2-styled.swal2-confirm {
    margin: 0 0.5rem;
    border-radius: 0;
}

.swal2-styled.swal2-cancel {
    border-radius: 0;
}

.swal2-popup {
    border-radius: 0;
}

/* swal popup ends*/

.screen_resolution {
    color: #000;
}

.row_orderB {
    position: fixed !important;
    top: 3.5rem !important;
    width: 100% !important;
    z-index: 9 !important;
    left: 0;
    background-color: #e3e3e3 !important
}

.custom-checkbox.boardCheck {
    position: absolute;
    top: .4rem;
    width: 20px;
    height: 20px;
    display: none;
    justify-content: left;
    /* opacity: 1 !important; */
    z-index: 1;
    left: .4rem;
}

.ecommerce-card.card:hover .boardCheck {
    display: flex;
}

.ecommerce-card.card .boardCheck.designchecked {
    display: flex;
}

.custom-checkbox.boardCheck input {
    width: 20px;
    height: 20px;
    border: #000 1px solid;
    margin: 0;
    position: absolute;
    z-index: 9;
    /* opacity: 1; */
}

.selectThumbnails {
    position: absolute;
    z-index: 99;
    top: .5rem;
    transform: translate(-50%,-10%);
    left: 19rem;
    top: 16%;
    font-size: .8rem;
}

.selection_boardcn {
    background: #f7f7f7;
    border-radius: 0;
    padding-right: 1rem;
    align-content: center;
    align-items: center;
    position: absolute;
    left: 6rem;
    height: 3.4rem;
    top: 0;
    font-size: .8rem;
    border-radius: 0;
    padding: .5rem;
    color: #0a66c2;
    font-weight: 500;
}

.clearSelection {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    text-align: left;
}
    .clearSelection:hover {
        background: #e3e3e3;
    }

#checkboard {
    display: none;
}

#boardswindow #checkboard {
    display: flex;
}

.app-ecommerce-details.fullmain {
    height: calc(100vh - 4rem);
    overflow: hidden;
    margin-top: 4rem;
}

.dthemeCard {
    display: flex;
    flex-direction: row;
    width: 100%
}

.columnB {
    flex-basis: 20%;
    width: 20%;
}

.columnA {
    flex-basis: 79.5%;
    width: 79.5%;
    padding-left: 0rem;
}

#AllBottomDesign .grid-view {
    grid-template-columns: 1fr;
    display: block;
}

.columnA {
    padding-top: 0;
    ; /* 20-12 view 3d plugin*/
    height: calc(100vh - 3.8rem) !important;
    overflow: hidden;
    position: relative;
}

.columnB {
    padding-top: 0; /* 20-12 view 3d plugin*/
    height: calc(100vh - 3.8rem) !important; /* 20-12 view 3d plugin*/
    overflow: auto;
}

.item-features {
    position: absolute;
    z-index: 9;
    width: auto;
    left: 2.5rem;
    top: 4rem;
    width: 15rem;
}

.qrBox.position-absolute.detailOpened {
    width: 15rem;
    justify-content: center;
    align-content: center;
    display: flex;
    align-items: center;
    left: 2.5rem;
    top: 1rem;
}



[dir] .avatar img {
    width: 2.5rem;
    height: auto;
}

.count_box {
    position: absolute;
    z-index: 9;
    bottom: 61px;
    font-size: .9rem !important;
    right: 6px;
    display: none;
}

.ecommerce-card.card:hover .count_box {
    display: flex;
}

[dir] #count_box .form-control:focus {
    width: 9rem;
    background: #efefef;
}
/* .count_box::after{
    content: 'mtr';
    position: absolute;
    right: 7.5rem;
    top: .5rem;
    } */
.btn.countaply {
    border-radius: .675rem;
    font-size: .875rem;
}

.input_custom_qty.form-control {
    padding-right: 2rem;
    width: 6rem;
    height: 2rem;
}

#count_box label {
    position: absolute;
    right: .2rem;
    top: .4rem;
    font-size: .675rem;
    color: #cecece;
}

#count_box .form-control {
    font-size: .9rem !important;
    color: #010101;
    background: #efefef !important;
    border-radius: .675rem;
}

[dir] #count_box .form-control:focus .countaply {
    display: flex;
}

.countBody {
    padding: .2rem .9rem;
    text-align: center;
    font-size: .775rem;
    color: #fff;
    background: #6d6d6d;
}

.count_box .btn {
    color: #000;
    border: 0;
    border-radius: 50px;
    background-color: #28c76f !important;
    position: static;
    z-index: 9;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .4rem;
}

#coll_main_page .iconthumb.btn-wishlist.iconstate.active {
    background-color: #0a66c2 !important;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    padding: .35rem;
    border: 0 !important;
}

[dir] .dark-layout #coll_main_page .iconthumb.btn-wishlist.iconstate svg {
    font-weight: 600;
    width: 16px;
    height: 16px;
}

.child h4.card-title {
    font-size: .9rem;
}


.boards_view ::-webkit-scrollbar {
    width: 1em;
}

.boards_view ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.boards_view ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


#viewtableOrder button {
    /*    border-color: #0a66c2 !important;
    background-color: #0a66c2 !important;*/
}

#headerOrder svg {
    color: #000 !important;
}

.showoverlayQty {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 7;
    padding: .2rem 0.56rem;
    top: 0.5rem;
    min-width: 6rem;
    font-size: .7rem;
    border-radius: 8px;
    color: #000;
    font-weight: 600;
    border-radius: 8px;
    right: 0;
    width: 50%;
}

.topIcon {
    cursor: pointer;
}
.topIcon.media {
    margin-left: 1rem;
}

#boardsmenu svg {
    width: 2.2rem;
    height: 2.2rem;
    margin: .5rem;
    padding: .5rem;
    border-radius: var(--borderRadiusRounded);
}

#boardsmenu {
    justify-content: center;
    display: flex;
    align-content: center;
}

[dir="ltr"] .badge.badge-up {
    right: 0px;
    ;
    top: -5px !important;
}

#notificationMenu .badge.badge-up {
    right: 0px;
}

.media .badge.badge-up {
    left: -1px !important;
    right: auto !important;
    top: 13px !important;   
}

.infoview {
    right: 6px;
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .35rem;
    border-radius: var(--borderRadiusSemiRound);
    top: -4.9rem;
    position: absolute;
    background-color: #ffffffa3;
}

    .infoview:hover {
        background-color: #fff;
        border-radius: var(--borderRadiusSemiRound);
    }

.thumbInfoData {
    position: absolute;
    z-index: 8;
    width: 100%;
    height: 100%;
    background: #6d6d6df2;
    padding: 1rem;
    color: #fff;
    font-weight: 500;
    font-size: .875rem !important;
}

.child .card-title {
    margin-bottom: 0 !important;
}

.thumbInfoData button.close {
    background-color: #000;
    padding: .5rem;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.thumbInfoData label {
    color: #fff;
}

.infoview.play {
    top: -5rem;
}
/* today */
.ecommerce-card.card .thumbInfoData {
    display: none;
}

.ecommerce-card.showedInfo .thumbInfoData {
    display: block;
}.tooltip {
    visibility: hidden;
    width: 90px; /* Adjust width as needed */
    background-color: gray;
    color: white;
    text-align: center;
    border-radius: 2px;
    padding: 3px;
    position: absolute;
    z-index: 1;
    top: 100%; /* Position the tooltip below the icon */
    left: 50%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.iconImgSearch:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
#_zoomImage1Parent {
    position: relative;
}

/*translate(-49.66%, -2731px) rotate(-90deg);*/
.svg-rulerCm.showcm.left {
    transform: translate(-49.35%, -587.2px) rotate(-90deg);
    background-color: #ffd;
    height: 1.05cm;
    z-index: 9;
}

.svg-rulerCm.showcm.bottom {
    background-color: #ffd;
    position: absolute;
    bottom: 0;
    z-index: 9;
    height: 1cm;
    left: 37.5px !important;
}

#toggleRuler {
    width: 1cm !important;
    height: 1cm !important;
}

.svg-rulerIn.showinch.left {
    transform: translate(-49.74%, -3563px) rotate(-90deg);
    height: 1cm;
    background-color: #ffd;
    z-index: 9;
    left: 0px !important;
    bottom: 55px !important;
}

.svg-rulerIn.bottom.showinch {
    background-color: #ffd;
    position: absolute;
    z-index: 9;
    height: 1cm;
    left: 8px;
    bottom: 0px;
}

#toggleRuler {
    border: 0;
    background: #514b4b;
    width: 1cm !important;
    height: 1cm !important;
}

.ps {
    overflow: auto;
    min-height: auto !important;
}

.sidebar-shop .scrollbar-container.ps {
    /* overflow: visible!important; */
    overflow: hidden;
}

.Toastify__toast-container {
    width: auto !important;
    max-width: 320px !important;
}

.banner_branding {
    /*  background: #535353;*/
    text-align: center;
    padding: 5rem;
    height: 16rem;
    margin-top: 4rem;
    background-repeat: repeat-x;
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    background: #E8EAFA;
    /*  background-image:linear-gradient(62deg, #7CB3EA 0%, #BA93DF 100%);*/
    /*background-image: linear-gradient(to right, rgba(115, 100, 100, 0.6), rgba(18, 32, 51, 0.9)), url(https://images.pexels.com/photos/4968585/pexels-photo-4968585.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1) !important;*/
    background-size: 100% !important;
    background-position: bottom !important;
}

.supplierInner .banner_branding {
    margin-top: 0;
}


.banner_branding::before {
    background-color: rgba(0, 0, 0, 0.37);
}

[dir="ltr"] .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0 !important;
    width: 14px;
    height: 22px;
    position: absolute;
    z-index: 1;
    right: 6px;
    opacity: 1;
}

[dir] .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: none !important;
}

[dir] .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none !important;
}

.multiselect-demo .multiselect-custom .country-item-value {
    align-items: center;
}

[dir] .p-multiselect .p-multiselect-label {
    padding: 0.438rem 0.5rem !important;
}

.p-component {
    font-size: .88rem;
    font-family: inherit;
}

.scissor {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    width: 1.4rem;
}

.topFavIcon {
    position: absolute;
    right: 3px;
    width: 1rem !important;
    padding: 0;
    padding: 0 !important;
    background: #6d6d6d;
    width: 1.2rem !important;
    top: 9px;
    height: 1rem !important;
}

.topFavList {
    position: relative;
    padding-left: .1rem !important;
}

.dthemeCard {
    overflow: hidden;
    height: calc(100vh - 3.8rem) !important;
    margin-bottom: 0;
}

.NewWishlist {
    color: #0a66c2 !important;
    position: absolute !important;
    bottom: 7px !important;
    top: auto !important;
    font-weight: 600;
}

.morevertical {
    background: #5c5c5c;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    color: #fff;
    position: absolute;
    right: 12px;
    /* background: #666; */
    display: flex;
    height: 21px;
    width: 21px;
    top: 12px;
    justify-content: center;
    align-items: center;
    /* border-radius: 50%; */
}

[dir] .morevertical svg {
    color: #fff;
}

.morevertical:hover {
    background: #0a66c2;
}

.designImage {
    min-width: 8rem;
    min-height: 8rem;
    max-height: 8rem;
    background-size: contain;
}

.S_button .closebtn {
    position: absolute;
    right: 6rem;
}

.S_button svg {
}

.modal-sm {
    max-width: 460px;
}

.collectionText {
    color: #101010;
}

    .collectionText:hover {
        color: var(--color-prime-hover) !important;
    }

button.iconstate.active:hover .addPlus {
    display: none;
}

.removeMinus {
    display: none;
}

button.iconstate.active:hover .removeMinus {
    display: block;
}
/* temp */
[dir] .bordered-layout .icon-wrap button.iconthumb.btn-wishlist.iconstate.active:hover .favhover.card {
    right: 39px !important;
}

[dir] .bordered-layout .icon-wrap button.iconthumb.btn-wishlist.iconstate.active:hover + .morevertical.right {
    display: flex;
}

.ecommerce-card.card:hover .morevertical.right {
    display: flex;
}

.morevertical.right {
    right: 0px;
    top: 37px;
    width: 2.2rem;
    height: 2.2rem;
    display: none;
    background: #5c5c5cbd;
    border: 1px solid #5c5c5cbd;
    box-sizing: border-box;
    border-radius: var(--borderRadiusSemiRound);
}

    .morevertical.right svg {
        color: #fff;
    }

.ecommerce-card.card:hover .morevertical.right {
    display: flex;
    z-index: 99;
}

.morevertical.right:hover svg {
    color: #000;
}

[dir="ltr"] .morevertical.right:hover {
    border: 1px solid #fff;
    background: #fff;
    width: 2.2rem;
    height: 2.2rem;
    box-sizing: border-box;
}

.morevertical {
    display: none;
}

[dir] .Toastify__toast-body {
    font-weight: 600;
}

.bookmark-wrapper .badge.badge-up {
    right: -26px !important;
    bottom: 5px !important;
    top: 0px !important;
}

.nav-item svg {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
    color: #010101;
}

[dir] .Toastify__toast {
    background-color: #2f2b3d;
}

.Toastify__toast .toastify-body {
    color: #fff;
    padding-left: 0;
}

.Toastify__toast .Toastify__close-button svg {
    height: 1rem;
    width: 1rem;
    fill: #fff;
}

.shareCollection {
    /* position: absolute; */
    right: 2rem;
    font-size: 13px;
}

    .shareCollection svg {
        width: 1.3rem;
    }

.grid-view .shareCollection {
    right: 1rem;
    bottom: 4rem;
    position: absolute;
}

.media-list a.media {
    color: #101010 !important;
}

    .media-list a.media:hover {
        background-color: rgba(10, 102, 194, 0.12);
        color: #0a66c2;
    }

[dir] .ecommerce-application .grid-view .ecommerce-card .item-description {
    margin-top: 0 !important;
}

.ecommerce-application .ecommerce-header-items {
    height: 3.4rem;
    align-items: center;
}

#fullview_back_page .navbar-header {
    display: none !important;
}

.child .card {
    border: 1px solid #a8a8a8;
}

.verticalHover {
    position: absolute;
    background: #fff;
    width: 5rem;
    right: 37px;
    top: 0px;
    font-size: .8rem;
    padding: .5rem;
    white-space: nowrap;
    border-radius: 4px;
    display: none;
}

    .verticalHover span {
        color: #000 !important;
    }

.morevertical.right:hover .verticalHover {
    display: flex !important;
    color: #000
}

.coll_wishname small {
    font-weight: 600 !important;
    font-size: 6rem;
}

.middlename {
    width: 90%;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    margin: 1rem;
    margin-right: 0;
}

.input-group-append .closebtn {
    /* right: 7rem; */
    /* right: 11rem; */
    position: absolute;
    top: .5rem;
    color: #000 !important;
    z-index: 9999;
}

[dir] .custom-checkbox .custom-control-label::before {
    width: 16px !important;
    height: 16px !important;
}

.cartPopup {
    position: absolute;
    left: -1px;
    width: calc(100% + 2px);
    height: auto;
    background: #fff;
    top: 0px;
    display: none;
    z-index: 8;
    border: 1px solid #5c5c5c;
   
}
/* ManishaD */
.cartPopup form {
    min-height: 20.5rem;
}
/* Rinku */
.createNew.btn {
    position: absolute;
    top: 0;
    right: 0;
}

.rightCreate {
    border-left: 1px solid #d1d4d7;
    padding-left: 2rem;
}

.modal-create {
    max-width: 600px;
}

.colorbox {
    bottom: 41px;
}
/*mediaquery starts*/
/* Rinku */
.createNew.btn {
    position: absolute;
    top: 0;
    right: 0;
}

.rightCreate {
    border-left: 1px solid #d1d4d7;
    padding-left: 2rem;
}

.modal-create {
    max-width: 600px;
}
/*  */
.ecommerce-card.cartOpened .cartPopup {
    display: block;
}

.cartIcon {
    position: absolute;
    width: 2.2rem;
    height: 2.2rem;
    background: #28c76f;
    color: #fff;  
    border-radius: var(--borderRadiusSemiRound);
    display: none;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    top: -40px;
    right: 6px;
}

[dir] .ecommerce-application .ecommerce-card:hover .card-body .cartIcon {
    display: flex;
    top: -5rem;
}

.cartSuccess_child {
    width: .4rem;
    height: .4rem;
    position: absolute;
    background: red;
    border-radius: 50%;
    top: 8px;
    right: 4px;
    display: none;
}

.cartSuccess .cartSuccess_child {
    display: block;
    ;
}

.cartPopup .close {
    position: absolute;
    right: 26px;
    top: 0;
    z-index: 9
}

.btn-innerCreate {
    background-color: var(--color-dark-black);
}

.headers {
    position: fixed;
    top: 1.2rem;
    z-index: 999;
    left: 3.5rem;
    font-size: 1.5rem;
}

.greyHeadinner {
    padding: .5rem;
    padding-left:  1.4rem;
    background: #e3e3e3 !important;
    background-color: rgb(227, 227, 227);
}


.row_orderB .order_head {
    padding-bottom: auto;
}

[dir="ltr"] .wishlistCheck .form-check-input {
    margin-left: 0 !important;
    margin-top: 0 !important;
}

[dir="ltr"] .wishlistCheck .custom-control-input {
    z-index: 9;
}

[dir="ltr"] .wishlistCheck {
    width: 1.285rem;
    height: 1.285rem;
    padding-left: 0!important;
}
.list-view .wishlistCheck {
    margin-left: 30%;
}
.grid-view .shareLink {
    position: absolute;
    right: .8rem;
    bottom: 6.5rem;
}

.thumbInfoData tr td:nth-child(2) {
    width: 60%;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre-wrap;
}

.thumbInfoData tr td:first-child {
    width: 20%;
}

.custom-checkbox .custom-control-input, .custom-radio .custom-control-input {
    z-index: 999;
}

[dir] .assign_collection .p-multiselect .p-multiselect-label {
    padding: 0.438rem 0.5rem;
    height: 2.5rem !important;
    align-items: center;
    display: flex;
}

[dir] .assign_collection .p-multiselect {
    width: 100%;
    border-radius: 0 !important;
}

#vieworderA .modal-body {
    background-color: #fff !important;
}

.is-invalid {
    color: #ef2853;
}
.imageDrop {
    background-color: #f4f4f4;
    border: 2px dashed #ccc;
    border-radius: 2px;
    color: #000;
    height: 310px;
    margin-bottom: 15px;
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    position:relative;
}
.iconImgSearch {
    position: absolute;
    right: 5rem;
    z-index: 9;
    top: .2rem;
    padding: .0rem 2rem;
    top: 3px;
    height: 79%;
    cursor:pointer;
}
button.browsedImg {
    background: #eeeeee !important;
    height: 2rem;
    margin-top: .2rem;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 150px;
    font-size: .8rem;
    position: absolute;
    white-space: nowrap;
    padding-left: 2rem;
    z-index:99
}
.browsedImg img {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: 2px;
}
span.closeimg {
    background: #5c5c5c;
    padding: 5px 5px;
    width: 1.2rem;
    height: 1.2rem;
    margin-left: .5rem;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    right: 2px;
    font-size: .7rem;
}
div#_actualBtnFront.activeState {
    background-color: #007aff!important;
}
div#_actualBtnFront {
    background-color: #56585f !important;
}
.passwordContainer {
    position: relative;
}
.passwordContainer .input-group-append {
        position: absolute;
        right: 0;
        top: 68%;
        transform: translate(-50%, -50%);
        border: 0;
    }
.passwordContainerInner {
    border: 0;
    background: none;
}
.clientGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
    grid-template-rows: repeat(auto-fit, minmax(0, 200px));
    /*    grid-template-rows: repeat(3, 200px);
*/ 
 /*   grid-auto-rows: minmax(200px, auto);*/
}
.imgsearch .modal-title {
    width: 100%;
}
.rightBT {
    display: flex;
    justify-content: end;
    text-align: right;
}
[dir=ltr] .modal .modal-header .close:hover, [dir=ltr] .modal .modal-header .close:focus, [dir=ltr] .modal .modal-header .close:active{
    transform:none!important;
}
[dir=ltr] .modal .modal-header .close {
    transform: none !important;
    
}
#createWindow .modal-title {   
    position: static;
    z-index: 9;
}


.btClose, #createWindow .close, #vieworderA .close {
    position: fixed;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
    z-index: 99;
    display: flex !important;
    justify-content: center;
    padding: 0;
    background-color: transparent;
    border: 0;
    right: 3rem;
    background: none;
    box-shadow: none;
    opacity:0.5;
}
    .btClose:hover, #createWindow .close:hover, #vieworderA .close:hover {
        opacity:1
    }
    .error-message.orderError {
        position: absolute;
        top: 5rem;
        left: 11px;
    }
#createWindow .modal-body {
    background: #fff;
    overflow: auto;
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
    .snackmenu {
        display: block;
    }

    div#off_floatfilter .sidebar-left, div#on_floatfilter .sidebar-left {
        display: block;
    }

    .toggle_filter {
        display: none !important;
    }

    [dir] div#Filter-fullview-viewPage {
        margin-top: 0 !important;
    }

    .app-ecommerce-details.fullmain {
        height: auto;
        overflow: auto;
    }

    .dthemeCard {
        flex-direction: column;
    }

    .columnB {
        flex-basis: 100%;
        width: 100%;
        height: auto;
    }

    .columnA {
        flex-basis: 100%;
        width: 100%;
        height: auto;
    }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .snackmenu {
        display: block;
    }
    /* filter related */
    div#off_floatfilter .sidebar-left, div#on_floatfilter .sidebar-left {
        display: block;
    }

    .toggle_filter {
        display: none !important;
    }

    [dir] div#Filter-fullview-viewPage {
        margin-top: -9.3rem !important;
    }

    .app-ecommerce-details.fullmain {
        height: auto;
        overflow: auto;
    }

    .dthemeCard {
        flex-direction: column;
    }

    .columnB {
        flex-basis: 100%;
        width: 100%;
        height: auto;
    }

    .columnA {
        flex-basis: 100%;
        width: 100%;
        height: auto;
    }

    #AllBottomDesign .grid-view {
        display: grid;
    }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .snackmenu {
        display: none;
    }
}

@media (min-width: 992px) {
    [dir=ltr] body .content-detached.content-right .content-body {
        margin-left: calc(18.6rem + 1.2rem) !important;
        /* min-height: calc(100vh - 13rem); */
    }

    #on_book_filter .content-detached.content-right .content-body {
        margin-left: calc(175px);
    }

    [dir] .inner_div {
        margin-top: 2rem;
        padding-top: 0rem;
    }

    .clientGrid{
        padding:0 2rem;
    }
}

@media all and (device-width: 810px) and (device-height: 1080px) and (orientation:portrait) {
    [dir] div#Filter-fullview-viewPage {
        margin-top: -9.3rem !important;
    }

    .app-ecommerce-details.fullmain {
        height: auto;
        overflow: auto;
    }

    .dthemeCard {
        flex-direction: column;
    }

    .columnB {
        flex-basis: 100%;
        width: 100%;
        height: auto;
    }

    .columnA {
        flex-basis: 100%;
        width: 100%;
        height: auto;
    }


    #AllBottomDesign .grid-view {
        display: grid;
    }
}

@media (max-width: 1201px) {
    [dir="ltr"] .header-navbar.fixed-top {
        left: 0;
        z-index: 9;
    }

    [dir="ltr"] .row_search.row {
        width: 100%;
    }
}

@media (max-width: 1441px) {
    [dir="ltr"] .header-navbar.fixed-top {
        left: 0;
        z-index: 9;
    }

    [dir="ltr"] .row_search.row {
        width: 100%;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .ecommerce-application .list-view .ecommerce-card {
        grid-template-columns: 1.2fr 2fr 1fr !important;
    }
}

@media only screen and (max-width: 767.98px ) {
    [dir] #viewtableOrder .list-view .ecommerce-card {
        display: flex;
    }

    [dir] #viewtableOrder .boardtable tr td {
        padding: 0 !important;
    }

    .ecommerce-application .list-view .ecommerce-card {
        grid-template-columns: 1fr;
    }

        .ecommerce-application .list-view .ecommerce-card .item-img {
            padding: 0 !important;
        }

        .ecommerce-application .list-view .ecommerce-card .card-body {
            margin-bottom: 0;
        }
}

@media only screen and (min-width:321px) and (max-width:768px) {
    [dir] #viewtableOrder .boardtable tr td {
        padding: 0 !important;
    }

    #viewtableOrder .list-view .ecommerce-card {
        display: flex;
    }

    .icon-wrap {
        display: flex;
        flex-direction: column;
    }
}
/* new search page by image */
div#searchPage .sidebar {
    width: 100vw;
    left: .0rem;
    top: 3.9rem;
    position: static;
}
div#searchPage .sidebar .sidebar-shop {
    width: 100vw !important;
    height: 8.5rem;
    left: -1.2rem;
}
div#searchPage .row_search.row{
    display: none;
}
div#searchPage .sidebar-left{
display: block;
}
div#searchPage #product-categories{
display: flex;
gap: .4rem;
}

div#searchPage div#searchBtn {
    position: static;
    bottom: 0;
    width: auto!important;
}
div#searchPage .input_custom{
width: 110px!important;
min-width: 110px!important;
}
 [dir=ltr]  #searchPage > #product-categories .input_custom{
    float: left!important;
}
#searchPage #product-categories label{
    text-align: left;
}
#searchPage #product-categories > div, #searchPage #product-categories > form{
    display: flex;
    justify-content: unset!important;
    flex-direction: column;
}
#searchPage #product-categories > div > div {  
    flex-direction: column!important;
}
#searchPage #product-categories .multiselect-demo{
    flex-direction: column!important;
    display: flex!important;
}
#searchPage.division {
    display: grid;
    grid-template-columns: 25% 1fr;
    margin-top: 4rem;
    background-color: #fff;
   padding: 0 4rem;
}
#searchPage .search-results{
    padding: 0;
}
.division{
    display:block;
}
.imageArea{
    display: none;
}
.imageCropArea img {
    width: 100%;
    overflow: hidden;
}
#searchPage .imageArea{  
    display: block;
    margin: 0 auto;
    width: 100%;

}
#searchPage .row_search_b{
    position: static!important;
}   
#searchPage #off_floatfilter .content-body {
    margin-top: 6.8rem;
    padding-left: 1.2rem;
}
#searchPage .view-options {
    display: flex ;
}

#searchPage  div#searchBtn {
    margin-top: 2.2rem;
}
.uploadDesignFabric {
    position: absolute;
    z-index: 99;
    left: 60px;
    display: block;
    cursor: pointer;
    top: 19px;    
}
.imageCropArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 1.5rem;
    height: 30rem;
    /*background: #dddddd66;*/
    border-radius: 4px;
}              
.division .imgbox {
    grid-column: 1 / 2;
    grid-row: 1 / -1;
}            
[dir] #searchPage .imageArea {
    margin: 0 auto;
    padding-top: 7rem;
}
#searchPage #on_floatfilter #searchPageInner .content-body {
    margin-left: 0!important;
}
input.arSearch {
    margin: 0;
    width: 100%;
    padding: .6rem;
    border: 0;
}
.divider{
    width: calc(100% - 24px);
    position: relative;
    display: flex;
    padding: 1rem;
    justify-content: center;
    margin: 0;

}
span.sIcon {
    position: absolute;
    right: 0;
    height: 100%;
    padding: .5rem;
    cursor: pointer;
}
.innerUploadbt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}
.innerUploadbt svg {
    stroke: #fff;
}
span.sIcon:hover {
    background-color: #f1f1f1;
}
/* , div#searchPage #product-categories form */
.searchWrap{
    overflow: hidden;
    border: 1px solid #666;
}
.img_searchHeader { 
    position: fixed;
    top: 0;
    z-index: 9999;
    height: 3.9rem;
    background: #FFF;
    width: 100vw;
    left: 0;
    display:none;
}
#searchPage .img_searchHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}
.uploadbt2 {
    background: #0768a3;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: .2rem;
}
.uploadbt2 > input {
    min-width: 500px;
    max-width: 500px !important;
    opacity:0;
}
.btinner {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: #fff;
}
.popup_close {
    padding: .5rem;
    cursor: pointer;
    background: #f3f3f3;
}
    .popup_close:hover {
        background: var(--color-prime-hover);
        color:#fff
    }
.mycolorway {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thumbnail-wrapper {
    display: flex;
    position: relative;
    overflow: hidden; /* Hide thumbnails that are out of view */
}

#_colorwayGallary {
    display: flex;
    position: relative;
    left: 0;
    position: absolute;
    transform: translate(-50%,-50%) !important;
    left: 50% !important;
    max-width: 500px !important;
    width: auto !important;
    bottom: 120px !important;
    justify-content: center!important;
}

    #_colorwayGallary .thumbnail {
        width: 100px; /* Thumbnail width */
        height: auto; /* Aspect ratio is maintained */
        margin-right: 10px; /* Space between thumbnails */
        cursor: pointer;
    }

.scroll-arrow {
    background-color: #fff;
    border: 1px solid black;
    padding: 10px;
    cursor: pointer;
}
#searchPage .filter_color_pattern{
display: flex;
}
.filter_color_pattern {
    display: none;
    justify-content: center;
    align-items: center;
}
.filter_color_pattern .form-check-input {
    opacity:1;
    z-index:1;
    position:static;
    margin-right:.5rem;
}
.filter_color, .filter_pattern {
    display: flex;
    clear: both;
    min-width: 90px;
    flex-direction: row;
}
#_actualBtnFront{
    top:47px!important;
}