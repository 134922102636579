/* theme dark start*/
:root{  
    --bg-dark-blackA:#282828;
    --bg-greyA:#6d6d6d;
  }

[dir] .dark-layout #createWindow .modal-content {
    border: none;
}

[dir] .dark-layout .card:hover {
    border: 1px solid #aeaeae;
}
/*26-19*/

[dir] .dark-layout .horizontal-layout.navbar-sticky .header-navbar {
    background-color: #535353;
}

.dark-layout .sidebar-shop, .dark-layout .sidebar {
    background: #464646;
    border-right: 1px solid #242424 !important;
}

.dark-layout .ecommerce-header-items {
    background: #282828;
}

.dark-layout .toggle_filter {
    background: #464646;
    border-bottom: 1px solid #242424;
    border-right: 1px solid #242424;
}

[dir] .dark-layout select.form-control:focus, [dir] .dark-layout .custom-select:focus {
    border-color: #464646;
    background: #282828;
    border-color: #919191 !important;
}

[dir] .dark-layout .mysearch_head {
    background-color: #464646 !important;
}

[dir] .dark-layout .p-multiselect {
    background-color: #6d6d6d;
    border-color: #464646;
    color: #e4e4e4 !important;
}

    [dir] .dark-layout .p-multiselect .p-multiselect-label.p-placeholder {

        color: #e4e4e4 !important;
    }

[dir] .dark-layout a.nav-link:hover, [dir] .dark-layout li.nav-item span.align-middle:hover {
    color: #e4e4e4 !important;
}

[dir] .dark-layout div#_fullScreenBtn:hover, [dir] .dark-layout div#_show3DImgList:hover, [dir] .dark-layout div#_resetBtn:hover, [dir] .dark-layout div#_actualBtnFront:hover, [dir] .dark-layout div#_addToCollection:hover, [dir] .dark-layout div#_addToCart:hover, [dir] .dark-layout div#centerZoomIcon:hover, [dir] .dark-layout div#_previousBtn:hover, [dir] .dark-layout div#_nextBtn:hover {
    background-color: #e4e4e4 !important;
}

[dir] .dark-layout .form-control {
    font-size: .88rem !important;
}

[dir] .dark-layout .text-primary {
    color: #fff !important;
}

[dir] .dark-layout .noUi-handle {
    background: #fff !important;
    border: 2px solid #fff !important;
}

[dir] .dark-layout .noUi-connect {
    background: #fff !important;
    box-shadow: none;
}

[dir] .dark-layout .noUi-target {
    background: #282828 !important;
    opacity: 1 !important;
}

[dir] .dark-layout .active-Bottom-image-for-fullview {
    border: 1px solid #fff;
}

[dir] .dark-layout .dthemeCard, [dir] .dark-layout .dthemeCard:hover {
    border: none !important;
}

[dir] .dark-layout .book_thumb, [dir] .dark-layout .customizer_menu {
    right: 0;
    background: #464646;
    border: none;
}
[dir] .dark-layout .book_thumb .btn {
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: #6d6d6d;
        margin: .2rem;
        border-radius: .2rem;
}
[dir] .dark-layout .pageactive span {
    background: #d12727;
    border-radius: 50px;
    color: #fff;
}

[dir] .dark-layout .book_thumb button.pageactive svg {
    color: #fff !important;
}

[dir] .dark-layout .collezioni_pages .mysearch_head {
    background-color: #464646 !important;
    border: none !important;
}

[dir] .dark-layout .customizer_menu button {
    color: #fff;
    max-height: 60px; /*26-09*/
    border-radius: .2rem;
    background: #6d6d6d;
    /* background-color: rgb(109, 109, 109); */
    margin: .1rem !important;
}

[dir] .dark-layout .btn-primary {
    border-color: #d12727 !important;
    background-color: #d12727 !important;
}

 [dir] .dark-layout .btn-primary:hover {
        background-color: #b31111 !important;
        border-color: #b31111 !important;
        box-shadow: none !important;
    }
[dir] .dark-layout .auth-wrapper.auth-v1 {
    background-color: #282828;
}

[dir] .dark-layout .ButtonGroup.groupfixed {
    width: 100%;
    z-index: 9;
    position: fixed;
    background-color: #464646 !important;
}
.dark-layout input.form-control, .dark-layout .custom-file-label, .dark-layout textarea.form-control {
    color: #ffffff;
}
.dark-layout .passwordContainer .input-group-text {
    color: #ffffff!important;
}

[dir] .dark-layout .ButtonGroup .btn-light {
    background: #464646;
    color: #fff;
    border: 0;
}

[dir] .dark-layout .ButtonGroup .btn.btn-light.active {
    border-color: #d12727 !important;
    background-color: #d12727 !important;
}

[dir] .dark-layout .filter-ico svg {   
    fill: #fff;
}
[dir] .dark-layout div#off_floatfilter .toggle_filter {
    border-color: var(--bg-greyA) !important;
    background-color:var(--bg-greyA) !important;
    color:#fff;
}
[dir] .dark-layout .search-results{
    background-color: var(--bg-dark-blackA);
}
[dir] .dark-layout .order_head {
    background: #535353;
}
[dir] .dark-layout .close {  
    color: #fff;
  }
[dir] .dark-layout #createWindow .modal-content {
    border: none !important;
}

.swiper-button-prev, .swiper-button-next {
    display: none;
}

[dir] .dark-layout #viewtableOrder .list-view .ecommerce-card, [dir] .dark-layout #viewtable .list-view .ecommerce-card {
    background: none !important;
}

[dir] .dark-layout .media:hover {
    background: rgb(83, 83, 83);
    color: #fff;
}

[dir] .dark-layout .pinkbg .header-navbar {
    background-color: #535353;
}


[dir] .dark-layout .select__menu .select__menu-list .select__option, .react-select__menu .select__menu-list .select__option, .select__menu .react-select__menu-list .select__option, .react-select__menu .react-select__menu-list .select__option, .select__menu .select__menu-list .react-select__option, .react-select__menu .select__menu-list .react-select__option, .select__menu .react-select__menu-list .react-select__option, .react-select__menu .react-select__menu-list .react-select__option {
    cursor: pointer;
}

    [dir] .dark-layout .select__menu .select__menu-list .select__option.select__option--is-focused, .react-select__menu .select__menu-list .select__option.select__option--is-focused, .select__menu .react-select__menu-list .select__option.select__option--is-focused, .react-select__menu .react-select__menu-list .select__option.select__option--is-focused, .select__menu .select__menu-list .react-select__option.select__option--is-focused, .react-select__menu .select__menu-list .react-select__option.select__option--is-focused, .select__menu .react-select__menu-list .react-select__option.select__option--is-focused, .react-select__menu .react-select__menu-list .react-select__option.select__option--is-focused {
        background-color: rgba(189, 189, 189, 0.5);
        color: #282828;
    }

    [dir] .dark-layout .select__menu .select__menu-list .select__option.select__option--is-selected, .react-select__menu .select__menu-list .select__option.select__option--is-selected, .select__menu .react-select__menu-list .select__option.select__option--is-selected, .react-select__menu .react-select__menu-list .select__option.select__option--is-selected, .select__menu .select__menu-list .react-select__option.select__option--is-selected, .react-select__menu .select__menu-list .react-select__option.select__option--is-selected, .select__menu .react-select__menu-list .react-select__option.select__option--is-selected, .react-select__menu .react-select__menu-list .react-select__option.select__option--is-selected {
        background-color: #282828;
        color: #fff
    }

;

[dir] .dark-layout .select__control {
    background-color: #282828;
    border-color: #282828;
}

[dir] .dark-layout .select__control .select__indicator svg {
    fill: 282828;
}
/* swal popup */
[dir] .dark-layout .swal2-styled.swal2-confirm {
    background-color: rgb(78, 81, 84) !important;
    border-radius: 0;
}

[dir] .dark-layout .swal2-styled.swal2-confirm {
    background-color: rgb(78, 81, 84);
    border-radius: 0;
}

[dir] .dark-layout .swiper-wrapper {
    flex-direction: column;
    /* transform: none!important;*/
}

[dir] .dark-layout .ecommerce-card.card {
    background-color: #282828;
    box-shadow: none;
    border: 1px solid grey;
}

[dir] .dark-layout .swiper.card {
    background-color: #282828;
}
[dir] .dark-layout #viewtableOrder .list-view .ecommerce-card{
    border: none;
}
[dir] .dark-layout [dir] .form-control:focus {
    border-color: #d0d2d6 !important;
}

.dark-layout input.form-control:focus {
    border-color: #d0d2d6 !important;
}

[dir] .dark-layout .table {
    background-color: #282828;
    color: #fff;
}

.dark-layout label {
    color: #fff;
}

.dark-layout .swal2-popup {
    background: #2d2d2d;
    border: #fff 1px solid;
}

.dark-layout .swal2-content {
    color: #fff;
}

.dark-layout h2#swal2-title {
    color: #fff;
}

.dark-layout .swal2-icon.swal2-warning {
    border-color: #fff;
    color: #fff;
}

.dark-layout .btn-outline-secondary {
    color: #fff;
}

.dark-layout .nav-link:hover {
    color: #fff;
}

[dir] .dark-layout .dropdown-menu .dropdown-item:hover, [dir] .dark-layout .dropdown-menu .dropdown-item:focus {
    background: rgb(83, 83, 83);
    color: #fff;
}

[dir] .dark-layout .dropdown-menu a:hover {
    color: #fff;
    text-decoration: none;
}

[dir] .dark-layout .dropdown-menu a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
}

[dir] .dark-layout .p-multiselect .p-multiselect-label.p-placeholder {
  /*  font-weight: 600;*/
}

[dir] .dark-layout .badge-primary {
}

[dir] .dark-layout .bg-light {
    background-color: #464646 !important;
}

[dir] .dark-layout .listname.form-group {
    border: 1px solid #2d2d2d;
}

[dir] .dark-layout .modal .modal-content {
    overflow: visible;
    border: 1px solid #fff !important;
    border-radius: 0;
}

[dir] .dark-layout .modal-body .text-secondary {
    color: #fff !important;
    opacity: 1 !important;
}

[dir] .dark-layout .ecommerce-application .ecommerce-card .btn-light {
    background-color: #6d6d6d !important;
    border-color: transparent;
    color: #fff !important;
}

    [dir] .dark-layout .ecommerce-application .ecommerce-card .btn-light:hover {
        background-color: #282828 !important;
    }

[dir] .dark-layout .favhover.card {
    background-color: #282828;
}

[dir] .dark-layout .boardn small {
    color: #1c1b1b;
    background: #fff;
}

[dir] .dark-layout .icon-wrap button.iconthumb.btn-wishlist.iconstate.active {
    background-color: #c84343 !important;
    border-radius: var(--borderRadiusSemiRound);
    width: 2.2rem;
    height: 2.2rem;
    padding: .35rem;
}

[dir] .dark-layout .badge-primary {
    background-color: #d12727;
}

[dir] .dark-layout .pagination:not([class*="pagination-"]) .page-item.active .page-link {
    background-color: #808080;
}

[dir] .dark-layout .pagination:not([class*="pagination-"]) .page-item .page-link {
    background-color: #6f6f6f2b;
}

[dir] .dark-layout .pagination:not([class*="pagination-"]) .page-item.active {
    background-color: #6f6f6f2b;
}

.dark-layout .pagination:not([class*="pagination-"]) .page-item .page-link:hover {
    color: #fff;
}

.dark-layout .page-item.next-item .page-link:hover {
    background: #f3f2f7 !important;
    background-color: rgb(243, 242, 247);
}

.dark-layout .page-item.prev-item .page-link:active,.dark-layout .page-item.prev-item .page-link:hover {
    background-color: #f3f2f7 !important;
}

[dir] .dark-layout .p-multiselect:not(.p-disabled):hover {
    border-color: none !important;
}

[dir] .dark-layout .mediaprofile {
    background: #2d2d2d;
    border: 1px solid #020202;
}

    [dir] .dark-layout .mediaprofile:hover {
        color: #fff;
        font-weight: 600;
        background: #5b5b5b;
    }

[dir] .dark-layout .btn-outline-primary {
    border: 1px solid #fff !important;
    background-color: transparent;
    color: #fff;
}

    [dir] .dark-layout .btn-outline-primary:hover {
        border: 1px solid #fff !important;
        background-color: rgb(240, 240, 240) !important;
        color: #000;
    }

[dir] .dark-layout .modal .modal-header .close {
    text-shadow: none;
    background-color: #fff !important;
    box-shadow: 0 3px 8px 0 rgba(11, 10, 25, 0.49) !important;
    color: #000;
}

[dir] .dark-layout .list-group .list-group-item:not([class*="list-group-item-"]):active {
    background-color: #232323;
}

[dir] .dark-layout .btn-group-toggle .btn-icon.active {
    background: #000 !important;
}

.dark-layout .btn-group-toggle label[class*="btn-outline-"] {
    color: #fff !important;
    background: #82868b;
}

.dark-layout .bn_name {
    color: #fff;
}

[dir] .dark-layout .custom-control-input:not(:checked) ~ .custom-control-label::before {
    background-color: #fff;
    border-color: #292929;
    border: 2px solid #292929;
}

[dir] .dark-layout .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #000;
}

[dir] .dark-layout a {
    color: #d05f5f;
}

[dir] .dark-layout .qrBox {
    background-color: #282828;
}

[dir] .dark-layout .favhover small:nth-child(2) {
    color: #fff;
}

[dir] .dark-layout .favhover svg {
    color: #fff;
}

[dir] .dark-layout .book_active.active {
    background-color: #d12727;
}

[dir] .dark-layout .bookcard.card {
    box-shadow: 3px 3px 0px 0px rgb(189, 189, 189) !important
}

[dir] .dark-layout #count_box .form-control {
    color: #010101;
    ;
    background: #3c3939;
}

[dir] .dark-layout .count_box::after {
    color: #010101;
}

[dir] .dark-layout #count_box .form-control:focus {
    background: #151313;
}

[dir] .dark-layout #coll_main_page .iconthumb.btn-wishlist.iconstate.active {
    background-color: #c84343 !important;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    padding: .35rem;
    border: 0 !important;
}

[dir] .dark-layout #coll_main_page .iconthumb.btn-wishlist.iconstate svg {
    font-weight: 600;
    width: 16px;
    height: 16px;
}

[dir] .dark-layout .showoverlayQty { 
    z-index: 7;
    padding: .1rem .4rem;
    left: 0.5rem;
    top: .5rem;
    min-width: 6rem;
    font-size: .7rem;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
}

[dir] .dark-layout .valuelabel {
    position: absolute;
    right: 2.9rem;
    color: #000;
    top: .4rem;
}

[dir] .dark-layout .auth-viewer {
    background-color: #282828 !important;
}

[dir] .dark-layout div#createWindow .close {
    border-color: #d12727 !important;
    background-color: #d12727 !important;
}

[dir] .dark-layout #headerOrder svg {
    color: #fff !important;
}

[dir] .dark-layout .modal .modal-title {
    color: #fff !important;
}

[dir] .dark-layout .bookcard.card {
    box-shadow: 3px 3px 0px 0px #282828 !important
}

.dark-layout input.search-product.form-control, .dark-layout input.search-product.form-control:focus {
    color: #fff !important;
}

.dark-layout .search-product.form-control::placeholder {
    color: #c8c8c8 !important;
}

.dark-layout #boardsmenu svg {
    background: #6d6d6d;
    overflow: visible;
}

[dir] .dark-layout .topIcon:hover svg {
    background: #c6c6c6 !important;
    color: #000 !important;
}

[dir] .dark-layout .child .card-body .btn:hover {
    background-color: #c6c6c6 !important;
    color: #000;
}

[dir] .dark-layout .infoview {
    background-color: #6d6d6d;
}

    [dir] .dark-layout .infoview:hover {
        background-color: #000;
        border-radius: 50%;
    }
    [dir] .dark-layout .nav-item svg {
        color: #fff;
      }

/* dark ends */
[dir] .dark-layout button.navbar-toggler.shop-sidebar-toggler {
    background-color: #6c6c6c;
}
[dir] .dark-layout #Filter-main-viewPage .closebtn{
    background-color: #fff !important;
  border-radius: 10px;
  color: #000 !important;
  margin-right: 10px;
  font-size: 0.5rem;
  padding: -1.5rem;
  padding: 0.3rem 0.2rem 0.2rem 0.2rem;
}
[dir] .dark-layout #Filter-main-viewPage .S_button{
      padding-right: 1.3rem;
}
[dir] .dark-layout #headerOrder .order_close{
    background-color: #fff !important;
  border-radius: 10px;
  color: #000 !important;
  margin-right: 10px;
  font-size: 0.5rem;
  padding: -1.5rem;
  padding: 0.3rem 0.2rem 0.2rem 0.2rem;
}
[dir] .dark-layout .banner_branding {
    background: #535353;   
}
[dir] .dark-layout .p-multiselect-panel{
 background: #282828;
    border-color: #919191;
    color:#e4e4e4;
}
[dir] .dark-layout .p-multiselect-panel .p-multiselect-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #000;
    background: #282828;  
}
[dir] .dark-layout .p-multiselect-panel .p-multiselect-items .p-multiselect-item .country-item{
    color:#e4e4e4!important;
}
[dir] .dark-layout .p-multiselect-panel{
border: #919191 1px solid !important;
}
[dir] .dark-layout .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    background: #52525e;
    color: #e4e4e4;
  }
[dir] .dark-layout .p-multiselect-panel .p-inputtext{
    background: #000;
    border: #919191 1px solid !important; 
  }
  [dir] .dark-layout .p-inputtext{
    color: #e4e4e4!important;
  }
  [dir] .dark-layout .p-inputtext:enabled:focus {
    box-shadow: none;
}
[dir] .dark-layout .multiselect-demo .multiselect-custom .country-item-value{
    color: #e4e4e4;
}
[dir] .dark-layout .scissor {
    stroke:#fff;
}
[dir] .dark-layout .topFavIcon {
    background: #6d6d6d;
}

[dir] .dark-layout .NewWishlist {
    color: #ff4b4b !important;
    position: absolute !important;
    bottom: 7px !important;
    top: auto !important;
    font-weight: 600;
  }
  [dir] .dark-layout  .triangle {
    border-color: transparent transparent #000 transparent;
  
}
[dir] .dark-layout .morevertical:hover {
    background: #c84343;
    border:  #c84343;;
  }
  [dir] .dark-layout .morevertical:hover svg{
    color: #fff;
  }
[dir] .dark-layout .ecommerce-header.row_search_b {  
    border-bottom: 1px solid #6d6d6d;
  }
  [dir] .dark-layout .ecommerce-application .ecommerce-searchbar .input-group{
    background-color: #6d6d6d
  }
  [dir] .dark-layout .ecommerce-searchbar svg{
    color:#fff
  }
  [dir] .dark-layout #viewtableOrder .list-view{
    border: 2px solid #424242
  }

  [dir] .dark-layout  #Filter-main-viewPage .row_search{
    border-bottom: 1px solid #101010;
  }
  .dthemeCard{
    overflow: hidden;
    height: calc(100vh - 3.8rem)!important;
    margin-bottom: 0;
}  
[dir] .dark-layout .collectionText{
    color:#fff!important;
}
[dir] .dark-layout  .cartPopup{
background: var(--bg-dark-blackA);
}
[dir] .dark-layout .ecommerce-application tbody.grid-view{
    background-color: #282828;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    [dir] .dark-layout .swiper-wrapper

{
    flex-direction: row;
    display: flex;
}
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    [dir] .dark-layout .swiper-wrapper {
        flex-direction: row;
        display: flex;
    }
}

@media (max-width: 575.98px) {
    [dir] .dark-layout .swiper-wrapper {
        flex-direction: row;
        display: flex;
    }
}

@media all and (device-width: 810px) and (device-height: 1080px) and (orientation:portrait) {

    [dir] .dark-layout .swiper-wrapper {
        flex-direction: row;
        display: flex;
    }
}
