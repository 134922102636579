:root{
    --color-prime:#007aff;
    --color-prime-hover:#08539e;
    --color-dark-black:#080808;
    --color-light-grey:#dadce0;
    --bgcolor-white:#fff;
    
  }

@media (max-width: 767px) {
    .pagination {
        margin-top: -1rem;
    }
    .clientGrid {
        padding: 0 .5rem !important;
        grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
        grid-template-rows: repeat(3, 150px);
    }
    html[dir] body .app-content {
        padding: 0rem 1.2rem !important;
    }
    [dir] .horizontal-layout.navbar-sticky .app-content{
       padding: 0rem 1.2rem!important;
    }
    [dir=ltr] div#Filter-fullview-viewPage{
        position: fixed;
        width: 100%;
        top: 0;
        height: 100vh;
        z-index: 99;
    }
    #fullview_back_page.header-navbar{
        width: 2.5rem;
        height: 2.5rem;
        min-height: 2.4rem;
        max-height: 2.4rem;    
        margin: 0;
        padding: 0!important;  
        justify-content: center;
        right: 0!important;
        left: auto;
    }
    [dir] .checkout-options {
        margin-top: 20rem;
      }
    #boardswindow #fullview_back_page {
        width: 100%;
        height: 4rem;
        min-height: 4.1rem;
      }
.boardsPage .boardtable {
        margin-top:3.4rem;
      }
    [dir] .header-navbar .navbar-container{
       padding: 0rem 1rem;
    }
    #collection_Window .header-navbar {
        width: 100%;
        height: 4rem;
        top: 0;
        min-height: 4rem;
      }
    #_show3DImgList{
        top: 51px!important;
    }
    div#off_floatfilter .toggle_filter {
        fill: var(--bgcolor-white);
      }
      div#off_floatfilter .toggle_filter:hover {
        color: #fff;
      }
    [dir] div#Filter-fullview-viewPage .BottomDesignThumEmpty .ecommerce-card:hover .icon-wrap,  [dir] div#Filter-fullview-viewPage .BottomDesignThumEmpty .ecommerce-card:hover .color-ways{
        display: none!important;
    }
    .swiper-wrapper .item-rating{
        display: none;
    }
    h1{
        font-size: 2rem;
    } 
    h2 {
        font-size: 1.5rem;
    }
    .ecommerce-application .grid-view {
        column-gap: 1rem !important;
      }
    #vieworderA, #createWindow{
        margin-left: -1rem !important;
        margin-right: -1rem !important;
        width: 100vw;
    }
    [dir] #vieworderA .modal-body, [dir] #vieworderA .modal-content, [dir] #createWindow .modal-body, [dir] #createWindow .modal-content{
        width: 100vw;
        border-radius: 0;
    }
    .colA .form-control {      
        width: 100% !important;
      }
   .optionsA {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 1rem;
        text-align: left;
        grid-column-start: initial;
        grid-column-gap: .5rem;
      }
      .colA.designcount {
        position: absolute;
        bottom: 8px;
        left: 1rem;
        display: flex;
        flex-direction: row;
      }
       #exampleTexta {   
        height: 4rem !important;
        margin-top: 1rem!important;
      }
      .row_orderB .order_head {
        padding-bottom: 3rem !important;
  }
    .colA {
        display: flex;
        flex-direction: column;
        margin-right:0rem!important;
      }
    .viOrder .view-options{
        flex-direction: column;
    }
    #headerOrder .input-group-merge{
       width: 100%!important;
       margin-bottom: .5rem;
    }

    tbody#_designInfoBody tr td {
        padding: 1px !important;
        font-size: 11px;
    }

    div#_infoTable {
        padding: 5px !important;
    }
    div#_qrcodeImage {
        width: 90px !important;
        height: 90px !important;
    }
    div#_qrcodeImage img {
        width: 100%;
    }
    [dir] .ecommerce-application .ecommerce-card:hover .card-body .cartIcon {
        display: flex;
        top: -32px;
        right: 6px;
    }
    [dir] .ecommerce-application .ecommerce-card:hover .card-body .infoview {    
        top: -9.5rem;
      }
    div#centerZoomIcon {
        display: none;
    }
    .app-ecommerce-details.fullmain {
        height: calc(100vh);
        overflow: auto;
        margin-top: 0 !important;
        position: fixed;
     
        width: 100%;
        top: 0;
    }

    [dir] .auth-wrapper.auth-v1{
        padding: 2.5rem 1.5rem 4rem;
    }
    [dir] .avatar {
        width: 40px;
        height: 40px;
    }
 .navbar-brand .brand-logo img {
        height: 2.5rem;
    }
    [dir="ltr"] .rightCreate {        
        margin: 0 !important;
        padding: 0 !important;
        border-left: none!important;
      }
    #viewtableOrder .list-view .ecommerce-card{
        display: block;
    }
    .modal-create .modal-body {
        flex-direction: column !important;
      }
    #viewtableOrder .list-view {
        display: block;
      }
    [dir] #viewtableOrder .boardtable tr td{
        padding: 0!important;
    }
    #viewtable .header-list-view {
        display: none;
      } 
      #viewtable thead {
        display: none;
      }
      #viewtableOrder thead{
        display: none;
      }
      [dir] .boards_view.orderpage{
        top: 1.7rem!important;
        padding-top:0!important ;
        height: calc(100vh - 3rem);
      }
      #viewtableOrder .list-view .ecommerce-card{
        display: flex;
    }
 [dir] .viewcol_boardpanel .btn-group-toggle{
        display: none;
    }
    .auth-wrapperMain .left-banner{
        display: none;
    }
    [dir] .search-results {
        display: block!important;
        background: none;
     }
    #rows-per-page {
        display: none;
      }
      #ecommerce-searchbar {
        padding-left: 4.5rem;
      }
      .board_collection_box #ecommerce-searchbar {
        padding-left: 0;
      }
 #board_Window #ecommerce-searchbar {
    padding-left: 0rem;
  }
   
    [dir="ltr"] .selectThumbnails {     
        left: 7.3rem;
    }      
    .banner_branding {
        height: 15rem;
        padding-top: 2rem;
        padding-left: 0;
        padding-right: 0;
        margin-top: -8.7rem;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }
    #_info {
        left: 27px !important;
        top: 3px!important;
    }
    .dthemeCard {        
        height:100%!important ;
    }
    [dir] #AllBottomDesign .swiper-container {
        overflow: scroll !important;
        padding-bottom: 0 !important;
    }
    .ecommerce-application .ecommerce-header-items {
        height: 3.4rem;
        align-items: center;
    }
    .header-navbar.navbar-brand-center .navbar-header {
        left: 27% !important;
     
    }
    .row_search.row{
        top: 4rem;
    }
    .ecommerce-header.row_search_b{
        top: 8.6rem;
    }
    .desktop_dropdown {
        display: none;
    }


    .content-body .ecommerce-application .body-content-overlay {
        z-index: 87 !important;
    }

    [dir] .design_scroll {
        margin-top: 0;
    }

    [dir="ltr"] .vertical-overlay-menu .navbar .navbar-header {
        left: 28%;
    }

    .show_mobile {
        display: block;
        padding: 1rem;
    }

    .filterheading .text-success {
        top: 16px;
        right: 16px;
        left: auto;
    }

    #boardsmenu .dropdown-menu {
        right: 0 !important;
        left: auto !important;
    }

    .boardsmenu .dropdown-menu {
        left: auto !important;
        right: 0 !important;
    }

    .mobile_Menu.dropdown {
        display: block;
    }
    /* .desktop_dropdown.dropdown{
        display: none;
    } */
    [dir] .sidebar-shop .card-body {
        padding-left: 1rem !important;
    }
    /* .desktop_dropdown {
        display: none;
    } */
    .vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu {
        top: -0.8rem !important;
        z-index: 1;
        /* right: 0!important;
        left: auto!important; */
        width: 50% !important;
        left: 0 !important;
    }

    [dir] .dropdown-menu .dropdown-item {
        display: flex;       
        padding: 0.2rem 1.28rem;
        font-size: .875rem;
       
    }

    .sidenav-overlay {
        z-index: 1;
        opacity: .5;
    }

  /*  .media-left {
        max-width: 140px;
        min-height: 130px !important;
        width: 150px !important;
    }*/

    select.sel_size {
        display: none;
    }

    .bookmark-icons {
        display: none;
    }

    .icon-wrap {
        display: none;
    }
    /* li.dropdown-user {
         display: none;
     } */

    .align-middle {
        display: none;
    }

    .main-menu .navbar-header .navbar-brand .brand-text {
        font-size: 1rem;
    }

    .navbar-brand {
        line-height: 0rem;
    }

    li.nav-item .navbar-brand {
        width: 7rem;
    }
    [dir] .login-header{
        padding-bottom: 0;
    }

        li.nav-item .navbar-brand img {
            width: auto;
            height: 2.8rem !important;
        }

    [dir] .parent {
        text-align: center;
        padding-top: 0;
    }

    .title_box {
        transform: translate(-50%, -50%);
        left: 50%;
        top: 6.5rem;
    }

    .icon-wrap {
        display: none!important;
    }

    [dir] .ecommerce-application .ecommerce-card:hover .favhover.card {
        display: block;
    }

    .dropdown-item .align-middle {
        display: block;
    }

    .auth-viewer .align-middle {
        display: inline-flex;
    }
    /* .boards_view {
         margin-top: -5.5rem;
         border: 1px solid red;
     } */
    /* [dir] .boardtable tr th, [dir] .boardtable tr td {
         padding: 1rem 0.5rem !important;
         border: 1px solid #e5e5e5 !important;
     } */
    ._zoomImage1Parent {
        overflow-x: hidden;
    }

    .profile-header {
        display: none
    }

    .collection_page_view {
        margin-top: -4rem;
    }

    [dir=ltr] body .content-detached.content-right .content-body {
        min-height: calc(100vh - 13rem);
        margin-right: 0 !important;
    }

    .collezioni_pages .shop-sidebar-toggler {
        display: block !important;
    }

    .ecommerce-application.myseasons .grid-view {
        grid-template-columns: 1fr;
    }

    .collezioni_pages .ecommerce-header.row_search_b {
        top: 118px !important;
        margin-left: 0;
        width: 100% !important;
    }

    #on_book_filter .ecommerce-header.row_search_b, #off_book_filter .ecommerce-header.row_search_b {
        margin-left: 0 !important;
    }

    .book_thumb {
        position: fixed !important;
        bottom: 0;
        z-index: 99;
        top: auto;
        width: 100% !important;
        height: 60px !important;
        transform: translate(0);
        justify-content: space-evenly;
        background: #ebebeb;
        left: 0;
    }

    .collhead {
        padding-left: 0;
    }

    .bookcard.card {
        min-height: 180px;
        border: 5px solid #ffffff !important;
        width: 145px;
    }

    .book_thumb button:nth-child(2) {
        display: none;
    }

    .book_thumb button {
        width: 50%;
    }

    .ecommerce-header.row_search.books {
        padding: 1rem;
        top: 0 !important;
        margin-left: 0;
        width: 175px;
        z-index: 1;
        background: #fff;
    }

    button.navbar-toggler.shop-sidebar-toggler {
        background-color:var(--bgcolor-white);  
    }

    [dir] div#ecommerce-searchbar select.form-control {
        width: 30% !important;
    }

    .sidebar .scrollbar-container {
        max-height: calc(100vh - 1rem);
    }

    .cardlayer.card {
        margin-bottom: 0.5rem !important;
    }

    .mobile-filter-text {
        width: 4rem;
        display: block;
    }
    .mobile-filter-text span{
        display:none;
    }
    [dir=ltr] .toggle_search_panel {
        right: 156px;
    }
    .banner_branding {
        padding-top: 13.5rem;
        padding-left: 0;
        padding-right: 0;
        margin-top: -4.5rem;
    }
    .navbar-toggler {
        position: fixed;
        top: 4.8rem;
        z-index: 9;
        height: -32.8rem;
        left: 0;
        margin-left: 0;
        height: 4.2rem;
      }
      .custom-control.wishlistCheck {
        position: absolute!important;
        left: .5rem;
        top: .5rem;
      }
      .shareLink {
        right: 1rem !important;
        bottom: 2rem;
      }
      [dir="ltr"] .shareLinkHead {
        left: 2rem;
        right: auto;
        top: .8rem;
      }
      .wishlistCheckHead .custom-control-input.form-check-input {
        position: absolute;
        z-index: 99;
      }
      .wishlistCheckHead{
        left: 1.5rem;
    position: absolute;
    top: 1rem;
      }
      .inner_div button, .inner_div hr {
        display: none;
      }
      .mobile_logo{
        width: 10rem;
        margin-bottom: 2rem;
        height: auto;
      }
      .mobile_logo img{
        width: 100%;
        height: auto;
      }
      .brandingInner {
        margin-top: 4rem;
      }

      .inner_div {
        margin-top: 2rem;    
    }
}

@media (max-width: 575.98px) {
    [dir] .horizontal-layout.navbar-sticky .app-content{
        padding: 0rem 1.2rem
     }

    h1{
        font-size: 2rem;
    }  
    h2 {
        font-size: 1.5rem;
    }
    .headers{
        font-size:1.2rem;
    }
    #headerOrder .input-group-merge{
        width: 70%;
     }
    #viewtable .header-list-view {
        display: none;
      }
    [dir] #viewtableOrder .boardtable tr td{
        padding: 0!important;
    }
      #viewtable thead {
        display: none;
      }
      [dir] .boards_view.orderpage{
        top: -5.3rem;
      }
      #viewtableOrder thead{
        display: none;
      }
   [dir] .viewcol_boardpanel .btn-group-toggle{
        display: none;
    }
    .auth-wrapperMain .left-banner{
        display: none;
    }
    [dir] .search-results {
        display: block!important;
        background:none;
        width: auto;
      }
      .selection_boardcn{
        background: #f7f7f7;    
        top: 3.3rem;
        left: 0; 
        padding-right: 1rem;
        width: 100%;
        justify-content: right;
      }
    #rows-per-page {
        display: none;
      }
    #ecommerce-searchbar {
        padding-left:  4.5rem;
      }
      .board_collection_box #ecommerce-searchbar {
        padding-left: 0;
      }

 #board_Window #ecommerce-searchbar {
    padding-left: 0rem;
  }
    .navbar-toggler {
        position: fixed;
        top: 4.1rem;
        z-index: 9;
        /* height: -32.8rem; */
        left: 0;
        margin-left: 0;
        height: 4.4rem;
        background-color: #5c5c5c;
      }
    [dir="ltr"] .selectThumbnails {   
        top: 114%;  
        left: 4.7rem;
    }   
    .ecommerce-application .ecommerce-header-items { 
        height: 3.4rem;
        align-items: center;
    }
    #rows-per-page.view-options {
        display: none !important;
    }
    [dir] #fullview_back_page .swiper-container {
        overflow: scroll !important;
        padding-bottom: 0 !important;
        
    }
    #AllBottomDesign .grid-view {
        display: flex!important;
        margin-top: 0;
        margin-right: 0;
    }
        #AllBottomDesign .grid-view .ecommerce-card .item-img {
            width: calc(100vw - 70vw) !important;
            min-height: 7.5rem !important;
        }
    [dir] .BottomDesignThumEmpty {
        margin-bottom: 0!important;
    }
    .design_open_view.card {
        height: calc(100vh - 18.6rem) !important
    }
    .columnB {
        height: 11.5rem !important;
        position: fixed;
        bottom: 0;
        overflow-y: hidden;
        background: #fff;
    }
     #Filter-main-viewPage .sidebar-shop {
        /* width: 261px!important; */
        width: 18.6rem !important;
        padding-left:1rem;
        top:0px
    }
        .show_mobile {
        display: block;
        padding: 1rem;
    }



    #boardsmenu .dropdown-menu {
        right: 0 !important;
        left: auto !important;
    }

    .boardsmenu .dropdown-menu {
        left: auto !important;
        right: 0 !important;
    }
    /* .desktop_dropdown {
        display: none;
    } */
    [dir] .sidebar-shop .card-body {
        padding-left: 1rem;
    }

    [dir=ltr] body .content-detached.content-right .content-body {
        margin-right: 0 !important;
        margin-top: 12.1rem;
    }
    /* .media-left {
        max-width: 147px;
        width: 80px;
    } */
    .collection_page_view {
        margin-top: -4rem;
    }

    .title_box {
        top: 6.5rem !important;
    }

    .grandparent {
        margin-top: 2rem;
    }

    .Toastify__toast {
        height: 6rem;
    }

    .toastify-header .designSwatch {
        width: 30px;
        height: 30px;
    }

    #commentwindow {
        width: calc(100vw - 20%);
        height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
        left: 20%;
    }

    .commentmodal {
        max-width: 100vw;
        max-height: 100vh;
    }

    .mobile-hide {
        display: none;
    }
     
    /* seasonal css */
    .collezioni_pages .shop-sidebar-toggler {
        display: block !important;
    }

    .ecommerce-application.myseasons .grid-view {
        grid-template-columns: 1fr;
    }

    .collezioni_pages .ecommerce-header.row_search_b {
        top: 118px;
        width: calc(100%);
        margin-left: 0;
    }

    #on_book_filter .ecommerce-header.row_search_b, #off_book_filter .ecommerce-header.row_search_b {
        margin-left: 0 !important;
    }

    .book_thumb {
        flex-direction: row !important;
        position: fixed !important;
        bottom: 0 !important;
        z-index: 99;
        top: auto !important;
        width: 100% !important;
        height: 60px !important;
        justify-content: space-evenly;
        left: 0 !important;
        transform: translate(0) !important;
        background: #ebebeb;
    }

    .collhead {
        padding-left: 0;
    }

    .bookcard.card {
        /* min-height: 180px; */
        border: 5px solid #ffffff !important;
        width: 145px;
    }

    .book_thumb button:nth-child(2), .book_thumb button:nth-child(3) {
        display: none;
    }

    .book_thumb button {
        width: 50% !important;
    }

    .book_thumb span {
        display: flex !important;
        position: static !important;
        padding: 2px;
    }

    .book_thumb .btn:hover span {
        padding: 2px !important;
    }

    .book_thumb .btn {
        justify-content: center;
    }

    .ecommerce-header.row_search.books {
        padding: 1rem;
        top: 0 !important;
        margin-left: 0;
        width: 175px;
        z-index: 1;
        background: #fff;
    }


    [dir] div#ecommerce-searchbar select.form-control {
        width: 30% !important;
    }

    .sidebar .scrollbar-container {
        max-height: calc(100vh - 1rem);
    }

    .cardlayer.card {
        margin-bottom: 1rem !important;
    }

    .mobile-filter-text {
        width: 4rem;
        display: block;
    }

    [dir=ltr] .toggle_search_panel {
        right: 156px;
    }

    [dir="ltr"] .btn-innerCreate {
        right: 0!important;
        z-index: 9;
        top: 0rem;
      }
      html[dir] body .app-content {
        padding: 0rem 1.2rem;
    }

    .content .body-content-overlay.show {
        display: block !important;
    }

    #off_floatfilter .content-body {
        margin-left: 0rem !important;
    }
    /* today */
    [dir="ltr"] .header-navbar.fixed-top {
        z-index: 99 !important;
    }
    /* filter related */
    div#off_floatfilter .sidebar-left, div#on_floatfilter .sidebar-left {
        display: block;
    }

    .toggle_filter {
        display: none !important;
    }

    [dir=ltr] .ecommerce-header.row_search_b {
        margin-left: 0 !important;
        left: 0;
        width: 100%;
    }

    .sidebar {
        top: 14.4rem;
        left: -20px;
    }

    [dir=ltr] .ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler {
        padding-left: 0;
        margin-top: 0;
        height: 3.4rem;
    }

    div#searchBtn {
        position: fixed;
        bottom: 70px;
        width: calc(18.6rem - 2rem);
    }

    [dir] div#Filter-fullview-viewPage {
        margin-top: 0;
    }


    .dthemeCard {
        flex-direction: column;
    }

    .columnB {
        flex-basis: 100%;
        width: 100%;
        height: auto;
    }

    .columnA {
        flex-basis: 100%;
        width: 100%;
        height: auto!important;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 575.98px) {
    #headerOrder .input-group-merge{
        width: 70%;
     }
   
    .vertical-overlay-menu .navbar .navbar-header .navbar-brand .brand-logo img {
        height: 3rem;
    }
    [dir] .cb {
        margin-top: 0.6rem;
    }
    #boardswindow {
        margin-top: 3.6rem;
      }
    [dir] .bordered-layout .wrapper .header-navbar { 
        border:none;
    }
    .auth-wrapper.auth-v1 .auth-inner {
        max-width: 300px !important;
        margin-top: 2.5rem;
    }

    .pinkbg {
        padding: 0px !important;
    }

    .grandparent {
        margin: 0px;
    }

    [dir=ltr] .header-navbar .navbar-container .show .dropdown-menu {
        right: 0px !important;
        width: 14rem !important;
        left: auto !important;
        top: 3.5rem !important;
    }

    .u_info {
        margin: 10px 35px 10px 35px !important;
    }

    .d_list {
        margin: 10px 45px 10px 45px !important;
    }

    .mybg .parent {
        height: 100vh;
        width: 100%;
        display: block;
        overflow: auto;
        padding-bottom: 100px;
    }

    table#viewtableOrder {
        position: relative;
        /* top: 4.1rem; */     
        border: 1px solid #e5e5e5 !important;
        overflow: auto;
        margin-top: 0px !important;
        /* padding-bottom:50px; */
        padding-bottom: 10px;
        top: 10rem;
    }

    .Toastify__toast {
        height: auto;
    }

    ul.dropdown-menu.dropdown-menu-right.show {
        top: 61px !important;
    }

    [dir] .boardtable tr th, [dir] .boardtable tr td {
        padding: 0.5rem !important;
        border: none !important;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    [dir] .viewcol_boardpanel .boards_view {      
        margin-top: 0rem;
      }
    .viewcol_boardpanel .view-options {
        position: relative !important;
    }

    select.form-control.cursor {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    select.form-control.cursor {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    [dir="ltr"] div#createWindow .close, [dir="ltr"] #vieworder .close, [dir="ltr"] #vieworderA .close{
        top: 0.9rem!important;
        right: 1.9rem;
    }

    .hr_line,
    .dpl {
        display: none !important;
    }

    .dark-layout h3 {
        color: #fff !important;
    }


    #Category {  
        overflow-x: auto;
        height: calc(100vh - 73px);
        left: 0;
    }

    ::-webkit-scrollbar {
        width: 0px !important;
    }

    .ButtonGroup .btn-light {
        width: max-content;
    }

    ul#book_catlog {
        top: 5.7rem !important;
        min-height: 38px;
        padding: 1rem 0.6rem 0.2rem 0.6rem !important;
    }

    #on_book_filter .ecommerce-application.myseasons .grid-view {
        column-gap: 0.5rem !important;
        padding: 0rem !important;
        grid-template-columns: 1fr 1fr 1fr !important;
    }

    #on_book_filter .toggle_search_panel {
        right: 145px !important;
    }

    #on_book_filter .input-group {
        width: 60% !important;
    }

    .swiper-button-prev {
        display: none !important;
    }

    .swiper-button-next {
        display: none !important;
    }

    .customizer_menu {
        display: none;
    }

    .mobile-menu {
        display: none;
    }

    .collezioni_pages .shop-sidebar-toggler {
        left: 70% !important;
        top: 70px !important;
        padding: 7px 10px 0px 10px !important;
        margin-left: -1.5rem !important;
    }

    [dir] .overlay {
        padding-top: 20% !important;
    }

    .ecommerce-application .sidebar-left .sidebar .sidebar-shop {
        top: 62px !important;
        width: 130px !important;
    }

    [dir] .ecommerce-application .sidebar-left .sidebar .card {
        width: 100px;
        min-height: 150px;
    }

    .collezioni_pages .sidebar-shop {
        max-width: 130px !important;
    }

    .sidebar .scrollbar-container {
        max-height: calc(100vh - 7rem) !important;
    }

    #boardsmenu li:nth-child(3) {
        display: none;
    }

    .D_them {
        display: none;
    }

        .banner_branding {
        height: 4rem!important;
        margin-top: 0rem;
        padding-top: 0rem!important;
        padding-left: 0;
        padding-right: 0;     
        padding: 0!important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    
}
