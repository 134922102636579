@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) {
    .show_mobile {
        display: block;
        padding: 1rem;
    }

    .header-navbar.fixed-top {
        z-index: 9;
    }

    .filterheading .text-success {
        top: -26px;
        left: 10px;
    }

    .boardsmenu .dropdown-menu {
        left: auto !important;
        right: 0 !important;
    }

    .vertical-overlay-menu .navbar .navbar-header .navbar-brand .brand-logo img {
        max-width: 150px !important;
    }

    .mobile_Menu.dropdown {
        display: block;
    }

    .desktop_dropdown.dropdown {
        display: none;
    }

    .mobile_Menu.dropdown .dropdown-menu {
        left: -1.5rem !important;
    }

    .user-nav span {
        display: block;
        text-align: left;
    }

    li.dropdown-user {
        display: block;
    }

    [dir=ltr] .sidebar {
        padding-left: 0;
        left: 0;
    }

    [dir] .sidebar-shop .card-body {
        padding-left: 1rem !important;
    }

    html[dir] .navbar-sticky .app-content {        
        padding: 0rem 1.2rem;
    }

    .inner_div {
        margin-top: 0rem;
        padding-top: 0;
        height: calc(100vh - 15rem);
    }

    li.nav-item .navbar-brand {
        /* width: 8rem; */
        line-height: .2rem;
        text-align: center;
        margin: 0;
    }

        li.nav-item .navbar-brand img {
            width: 100%;
        }

    .bookmark-icons {
        display: none;
    }

    .snackmenu {
        display: block;
    }

    #Filter-main-viewPage .row_search.row {
        margin-right: -1.2rem;    
        padding-left: 5.6rem !important;
    }
    /* #Filter-main-viewPage .row_search.row {
        padding-left: 5.6rem !important;
      } */
 

    .align-middle {
        display: none;
    }

    .auth-viewer .align-middle {
        display: inline-flex;
    }

    .dropdown-menu .align-middle {
        display: inline-block;
    }

    [dir=ltr] div#Filter-fullview-viewPage {
        margin-left: -1.2rem;
        margin-right: -1.2rem;
        margin-top: -6rem;
    }

    [dir=ltr] .ecommerce-application .ecommerce-card .btn-wishlist svg {
        margin-right: 0;
    }

    [dir] .ecommerce-application .ecommerce-card:hover .icon-wrap {
        display: flex;
    }

    [dir] .ecommerce-application .ecommerce-card:hover .favhover.card {
        display: block;
    }

    .icon-wrap {
        display: none;
    }

    .collection_page_view {
        margin-top: -3.5rem;
    }

    select.sel_size {
        display: none;
    }

    .profile-header {
        display: none
    }

    .favhover.card {
        width: 147px;
        height: 56px;
    }

    [dir=ltr] body .content-detached.content-right .content-body {
        min-height: calc(100vh - 15rem);
    }

    [dir] .chat-app-window {
        width: 100%;
    }

    #commentwindow {
        left: 20%;
    }

    #commentwindow {
        width: 80vw;
        height: 100vh;
        max-width: 80vw;
        max-height: 100vh;
    }

    .commentmodal {
        max-width: 100vw;
    }

    [dir] .ecommerce-application .search-product, [dir] .ecommerce-application .input-group-text {
        padding-right: 3.5rem;
    }

    .ecommerce-application .grid-view {
  /*      grid-template-columns: 1fr 1fr 1fr !important;*/
    }

    .toggle_filter {
        display: none !important;
    }

    div#off_floatfilter .sidebar-left, div#on_floatfilter .sidebar-left {
        display: block;
    }

    .content .body-content-overlay.show {
        display: block;
    }

    .header-navbar {
        z-index: 9;
        flex-flow: row nowrap;
    }

    [dir=ltr] .ecommerce-header.row_search_b {
        margin-left: 0 !important;
        left: 0;
        width: 100% !important;
    }

    [dir=ltr] body .content-detached.content-right .content-body {
        min-height: calc(100vh - 15rem);
        margin-left: 0 !important;
    }
    /* .book_thumb {
    flex-direction: row!important;
        position: fixed!important;
        bottom: -2px;
        z-index: 99;
        width: 100%;
        top: auto!important;
        padding: 0rem;
        background: #ebebeb;
        justify-content: space-evenly;
        transform: translate(0);
         left: 0;
} */
    .book_thumb .btn {
        justify-content: center;
    }

    .book_thumb span {
        display: flex !important;
        position: static !important;
        padding: 2px;
    }

    .book_thumb button:nth-child(2) {
        display: none;
    }

    .collezioni_pages .shop-sidebar-toggler {
        display: block !important;
    }

    .mobile-filter-text {
        font-size: 1rem;      
        display: flex;
        max-height: 2.3rem;
        min-height: 2.3rem;
        padding: .4rem;
    }
    .navbar-toggler.shop-sidebar-toggler {
        position: fixed;
        top: 4rem;
        z-index: 99;
        height: 4.4rem;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        width: 6rem;
    }
    div#product-categories {
        margin-left: 1rem;
    }
    .collhead {
        padding-left: 0;
    }

/*    button.navbar-toggler.shop-sidebar-toggler {
        background-color: #010101;        
    }*/

    [dir] div#ecommerce-searchbar select.form-control {
        width: 15% !important;
    }

    /*akash*/
    .auth-wrapper.auth-v1 .auth-inner {
        max-width: 350px !important;
    }

    .media-left img {
            max-height: 130%;
  }

    .t_themes {
        max-width: 400px !important;
    }

    table#viewtableOrder {
        position: relative;
        /* top: 4.1rem; */
        display: inline-block;
        border: 1px solid #e5e5e5 !important;
        overflow: auto;
        margin-top: 0px !important;
        /* padding-bottom:50px; */
        padding-bottom: 10px;
    }

    [dir] .boardtable tr th, [dir] .boardtable tr td {
        padding: 1rem 0.5rem !important;
        border: 1px solid #e5e5e5 !important;
    }

    .mybg .parent {
        height: auto;
        width: 100%;
        display: block;
        overflow: auto;
        padding-bottom: 100px;
    }

    #Category .title_box {
        top: 8rem;
    }

    #Category .child {
            height: auto;
            width: 200px;
         
    }

    #Category .inner_div {
        height: calc(100vh - 13rem) !important;
    }

    /* .grandparent {
    top: 11rem !important;
} */

    #book_list .child {
        width: 300px;
        height: 240px;
        margin: 20px;
    }

    #book_catlog .nav-item .btn {
        width: max-content;
    }

    .mobile-menu {
        display: none;
        ;
    }

    .book_thumb {
        height: auto !important;
    }

    .customizer_menu {
        display: none;
    }

    #on_book_filter .ecommerce-application.myseasons .grid-view {
        column-gap: 1.5rem !important;
    }

    [dir=ltr] .toggle_search_panel {
        right: 326px !important;
        display: none;
    }

    .ecommerce-application .sidebar-left .sidebar .sidebar-shop {
        top: 8.8rem !important;
    }

    [dir=ltr] .collezioni_pages .shop-sidebar-toggler {
        left: 440px !important;
        top: -15px;
        transform: translate(50%,50%);
        z-index: 1;
    }

    [dir=ltr] .book-container, [dir=ltr] .demo-book {
        max-width: 720px;
        position: fixed;
        transform: translate(-52%, -57%) !important;
    }

    /* .navbar-scrolled{
    position: fixed;
} */

    .swiper-button-next,
    .swiper-button-prev {
        display: none !important;
    }
}

@media only screen and (min-device-width: 820px) and (max-device-width: 1024px) and (orientation:portrait) {
    .show_mobile {
        display: block;
        padding: 1rem;
    }

    .filterheading .text-success {
        top: -26px;
        left: 10px;
    }

    #boardsmenu .dropdown-menu {
        right: 0.auto !important;
    }

    .vertical-overlay-menu .navbar .navbar-header .navbar-brand .brand-logo img {
        max-width: 150px !important;
    }

    .boardsmenu .dropdown-menu {
        left: auto !important;
        right: 0 !important;
    }

    .mobile_Menu.dropdown {
        display: block;
    }

    .desktop_dropdown.dropdown {
        display: block;
    }

    .user-nav span {
        display: block;
        text-align: left;
    }

    li.dropdown-user {
        display: block;
    }

    [dir=ltr] .sidebar {
        padding-left: 0;
        left: 0;
    }

    [dir] .sidebar-shop .card-body {
        padding-left: 1rem !important;
    }

    .inner_div {
        margin-top: 0rem;
        padding-top: 0;
        height: calc(100vh - 15rem);
    }

    [dir=ltr] .ecommerce-header.row_search_b {
        margin-left: 0 !important;
        left: 0;
        width: 100%;
    }

    .row_search.row {
        margin-right: -1.2rem;
        margin-left: -1rem;
    }

    .navbar-header {
        display: block;
    }

    .icon-wrap {
        display: block;
    }

    [dir] .ecommerce-application .ecommerce-card:hover .icon-wrap {
        display: flex;
    }

    .profile-header {
        display: none
    }

    .favhover.card {
        width: 147px;
        height: 56px;
    }

    .collezioni_pages .content-detached.content-right .content-body {
        margin-left: 0;
    }

    [dir] .chat-app-window {
        width: 100%;
    }

    #commentwindow {
        left: 20%;
    }

    #commentwindow {
        width: 80vw;
        height: 100vh;
        max-width: 80vw;
        max-height: 100vh;
    }

    .commentmodal {
        max-width: 100vw;
    }

    [dir] .ecommerce-application .search-product, [dir] .ecommerce-application .input-group-text {
        padding-right: 3.5rem;
    }

    .ecommerce-application .grid-view {
       /* grid-template-columns: 1fr 1fr 1fr !important;*/
    }

    .toggle_filter {
        display: none !important;
    }

    div#off_floatfilter .sidebar-left, div#on_floatfilter .sidebar-left {
        display: block;
    }

    .content .body-content-overlay.show {
        display: block;
    }

    .header-navbar {
        z-index: 9;
        flex-flow: row nowrap;
    }

    [dir=ltr] body .content-detached.content-right .content-body {
        min-height: calc(100vh - 15rem);
        margin-left: 0 !important;
    }
    /* .book_thumb {   
        flex-direction: row!important;
        position: fixed!important;
        bottom: -2px;
        z-index: 99;
        width: 100%;
        top: auto!important;
        padding: 0rem;
        background: #ebebeb;
        justify-content: space-evenly;
        transform: translate(0);
        left: 0;
    } */
    .book_thumb .btn {
        justify-content: center;
        /* width: 50%!important; */
    }

    .book_thumb span {
        display: flex !important;
        position: static !important;
        padding: 2px;
    }

    .book_thumb button:nth-child(2) {
        display: none;
    }

    .collezioni_pages .shop-sidebar-toggler {
        display: block !important;
    }

    .mobile-filter-text {
        font-size: .875rem;
        width: 9rem;
        padding: 1rem;
        display: inline-block !important;
    }
        .mobile-filter-text span {
            display: none;
        }
    .collhead {
        padding-left: 0;
    }

/*    button.navbar-toggler.shop-sidebar-toggler {
        background-color: #010101;
        margin-left: -1rem;
    }*/

    [dir] div#ecommerce-searchbar select.form-control {
        width: 15% !important;
    }
}

@media only screen and (min-device-width: 768px) and (orientation:portrait) {
    [dir=ltr] .collezioni_pages .shop-sidebar-toggler {
        left: 400px;
    }
}
