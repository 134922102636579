@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:landscape) {
    .inner_div {
        padding-top: 0rem !important;
    }

    [dir="ltr"] .row_search.row {
        margin-left: -2rem;
        width: 100%;
    }

    .header-navbar.fixed-top {
        z-index: 9;
    }

    .ecommerce-application .grid-view {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr !important;
        column-gap: 2rem;
    }

    [dir=ltr] .row_search.row {
        margin-left: -2rem;
    }

    [dir=ltr] .row_search.row {
        margin-right: -2.2rem;
        margin-left: -1.2rem;
    }

    [dir=ltr] div#Filter-fullview-viewPage {
        margin-left: -2.2rem;
        margin-right: -2.2rem;
        margin-top: -6rem;
    }

    .icon-wrap {
        display: none;
    }

    [dir] .ecommerce-application .ecommerce-card:hover .favhover.card {
        display: block;
    }

    div#Filter-main-viewPage .row_search {
        margin-left: -2rem !important;
    }

    .favhover.card {
        width: 147px;
        height: 56px;
    }

    .ecommerce-application .list-view .ecommerce-card {
        grid-template-columns: 1.2fr 2fr 1fr !important;
    }

    [dir=ltr] body .content-detached.content-right .content-body {
        min-height: calc(100vh - 15rem);
    }

    #commentwindow {
        left: 20%;
    }

    #commentwindow {
        width: 80vw;
        height: 100vh;
        max-width: 80vw;
        max-height: 100vh;
    }

    .commentmodal {
        max-width: 100vw;
    }

    [dir] .ecommerce-application .search-product, [dir] .ecommerce-application .input-group-text {
        padding-right: 3.5rem;
    }

    .sidebar {
        left: 0rem;
    }

    [dir] div#ecommerce-searchbar select.form-control {
        width: 10% !important;
    }

    #on_book_filter .grid-view {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        column-gap: 2rem;
    }

    /*akash*/
    .auth-wrapper.auth-v1 .auth-inner {
        max-width: 350px !important;
    }

    .media-left {
        height: 130px !important;
        width: 260px;
    }

        .media-left img {
            max-height: 130%;
        }

    .t_themes {
        max-width: 400px !important;
    }

    .mybg .parent {
        height: 100vh;
        width: 100%;
        display: block;
        overflow: auto;
        padding-bottom: 100px;
    }

    #Category .title_box {
        top: 8.5rem;
    }

    #Category .child {
        height: 336px;
        width: 270px;
    }

    #Category .inner_div {
        height: calc(100vh - 13rem) !important;
    }

    #book_list .child {
        width: 280px;
        height: 240px;
        margin: 20px;
    }

    #book_catlog .nav-item .btn {
        width: max-content;
    }

    .book_thumb {
        height: auto !important;
    }

    .customizer_menu {
        display: none;
    }

    [dir=ltr] .book-container, [dir=ltr] .demo-book {
        max-width: 600px;
        position: fixed;
        transform: translate(-52%, -57%) !important;
    }
}

@media only screen and (min-device-width: 820px) and (max-device-width: 1024px) and (orientation:landscape) {
    [dir="ltr"] .row_search.row {
        margin-left: -2rem;
        width: 100%;
    }

    .header-navbar.fixed-top {
        z-index: 9;
    }

    .inner_div {
        padding-top: 0rem !important;
    }

    [dir=ltr] .row_search.row {
        margin-right: -1.2rem;
        margin-left: -1.2rem !important;
    }

    .ecommerce-application .grid-view {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2rem;
    }

    #on_floatfilter .grid-view {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr !important;
        column-gap: 2rem;
    }

    #on_book_filter .grid-view {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        column-gap: 2rem;
    }

    #off_book_filter .grid-view {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        column-gap: 2rem;
    }

    #off_floatfilter .grid-view {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        column-gap: 2rem;
    }

    [dir=ltr] div#Filter-fullview-viewPage {
        margin-left: -2.2rem;
        margin-right: -2.2rem;
        margin-top: -6rem;
    }

    .icon-wrap {
        display: none;
    }

    [dir] .ecommerce-application .ecommerce-card:hover .favhover.card {
        display: block;
    }

    div#Filter-main-viewPage .row_search {
        margin-left: -2rem !important;
    }

    .favhover.card {
        width: 147px;
        height: 56px;
    }

    .ecommerce-application .list-view .ecommerce-card {
        grid-template-columns: 1.2fr 2fr 1fr !important;
    }

    [dir=ltr] body .content-detached.content-right .content-body {
        min-height: calc(100vh - 15rem);
    }

    #commentwindow {
        left: 20%;
    }

    #commentwindow {
        width: 80vw;
        height: 100vh;
        max-width: 80vw;
        max-height: 100vh;
    }

    .commentmodal {
        max-width: 100vw;
    }

    [dir] .ecommerce-application .search-product, [dir] .ecommerce-application .input-group-text {
        padding-right: 3.5rem;
    }

    .sidebar {
        left: 0rem;
    }

    [dir] div#ecommerce-searchbar select.form-control {
        width: 10% !important;
    }
}
