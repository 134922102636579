/* style css */
.modal-backdrop.in {
    opacity: 0.5;
}

#_setDPIModal .modal-footer {
    text-align: center;
}

#_setDPIModal .modal-dialog {
    width: 1000px !important;
    max-width: 1000px;
}

#_setDPIModal .modal-body {
    height: 75vh;
}

#_setDPIModal .measurement {
    height: 70vh;
    border: 1px solid #ed1c24;
}

#_setDPIModal .btn-danger {
    background: #cd2724;
}

#_setDPIModal .measuresment_box {
    /*border:1px solid #ccc;*/
    height: 70vh;
}

#_setDPIModal .form-control {
    border-radius: 0;
}

#_setDPIModal .screen_resolution, .screen_size {
    margin-left: 0 !important;
    margin-right: 0 !important;
    position: relative;
    padding-top: 20px;
    height: 178px;
    border: 1px solid #ccc;
}

#_setDPIModal .screen_size:after {
    content: 'Screen Size';
    display: block;
    position: absolute;
    width: auto;
    top: -15px;
    left: 98px;
    font-size: 14px;
    border: 1px solid white;
    background: #fff;
    padding: 0 9px;
}
    #_setDPIModal .screen_resolution {
        display: flex;
        flex-direction: column;
    }
    #_setDPIModal .screen_resolution:after {
        content: 'Screen Resolution';
        display: block;
        position: absolute;
        /*     width: 120px;*/
        width: auto;
        top: -12px;
        font-size: 14px;
        background: #fff;
        padding: 0 9px;
    }

#_setDPIModal label {
    font-weight: 500 !important;
    font-size: 14px;
}

#_setDPIModal .btn-number {
    border-radius: 0 !important;
}

#_setDPIModal input {
    text-align: center;
    max-width: 100%;
}

#_setDPIModal .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
    margin-right: 0px;
    padding: 10px 16px;   
}

#_setDPIModal .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
 /*   margin-left: -90px;*/
    padding: 10px 16px;
}

#_setDPIModal .input-group {
    margin-top: 10px;
}

 #_setDPIModal .input-group .form-control {
        width: 45%;        
}
#_setDPIModal .modal-header .close {
    opacity: 1;
    transition: all 0.23s ease 0.1s;
    position: relative;
    right: 28px;
    position: absolute;
}
#_setDPIModal .density {
    border: 1px solid #ccc;
    /*height: 265px;*/
    height: 29.3vh;
    text-align: center;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: none;
    font-size: 14px;
}
.input-group .input-group-btn {
    width: 50px;
    height: 2.714rem;
    background: #e5e5e5;
    text-align: -webkit-center;
    justify-content: center;
    align-items: center;
}
#_setDPIModal .col-md-offset-1 {
    margin-left: 3.333333%;
    float: left;
}
span.glyphicon {
    font-size: 2.2rem;
    line-height: .5;
}
[dir] .input-group .input-group-btn:hover {
    background: #0a66c2;
    color:#fff;
}
    [dir] .input-group .input-group-btn:hover span {
        color: #fff;
    }
    #_setDPIModal .col-md-7 {
        margin-left: 2%;
        float: left;
    }

#_setDPIModal .aspect_ratio {
    border: 1px solid #ccc;
    text-align: center;
    border-top: none;
    margin-top: 0px;
    padding: 7px;
    font-size: 14px;
}

#_setDPIModal .checkbox {
    position: static !important;
}

#_setDPIModal .modal-header {
    margin-bottom: 2rem;
    justify-content: flex-start;
}

#_setDPIModal input[type=radio] {
    display: inline-block;
    -webkit-appearance: button;
    width:14px;
    height:14px;
}
.screen_size .form-group:first-child {
    display: flex;
}
.screen_size .form-group:nth-child(2) {
    display: flex;
}
#_setDPIModal input[type=checkbox] {
    display: inline-block;
    -webkit-appearance: button;
}
input#hResolution {
    width: 100%;
}
.density .input-group {
    width: 100%;
    display: flex;
    padding: 0.5rem !important;
}
input#aspect_ratio {
    margin-right: 0.5rem;
}
.measuresment_box .form-group {
    padding: 0.2rem;
}
@media only screen and (max-device-width: 1366px) and (min-device-width: 1280px) {

    #_setDPIModal .density {
        height: 29vh;
    }
}

/*  riler css */

.rulerH {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    background: #eee;
    color: #000;
    font-family: arial;
    font-size: 12px;
    line-height: 14px;
    border-bottom: 1px solid;
    overflow: hidden;
    z-index: 9;
}

    .rulerH > div {
        display: inline-block;
        background: #000;
    }

    .rulerH .tickLabel {
        position: relative;
        top: -12px;
        margin-left: 9px;
        width: 1px;
        height: 100%;
        text-indent: 3px;
        line-height: 17px;
    }

        .rulerH .tickLabel:first-of-type {
            margin-left: 24px;
        }

    .rulerH .tickMajor {
        margin-top: 14px;
        margin-left: 9px;
        width: 1px;
        height: 6px;
    }

    .rulerH .tickMinor {
        margin-left: 9px;
        width: 1px;
        height: 4px;
    }


/*--------------------------------------------------------------------*/

.rulerV {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 100%;
    background: #eee;
    color: #000;
    font-family: arial;
    font-size: 12px;
    line-height: 14px;
    border-right: 1px solid;
    overflow: hidden;
    z-index: 9;
}

    .rulerV > div {
        display: block;
        background: #000;
    }

    .rulerV .tickLabel {
        position: relative;
        /*top: -10px;*/
        margin-top: 9px;
        height: 1px;
        width: 100%;
        text-indent: 5px;
        line-height: 20px;
    }

        .rulerV .tickLabel:first-of-type {
            margin-top: 24px;
        }

    .rulerV .tickMajor {
        margin-left: 19px;
        margin-top: 9px;
        height: 1px;
        width: 6px;
    }

    .rulerV .tickMinor {
        margin-left: 19px;
        margin-top: 9px;
        height: 1px;
        width: 4px;
    }

/*----------------------------------------------------------*/
.tickLabelH {
    position: relative;
    /*top: -10px;*/
    display: inline-block;
    width: 1px;
    height: 100%;
    text-indent: 3px;
    line-height: 20px;
    background-color: darkgray;
}

.tickLabelV {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    text-indent: 3px;
    line-height: 20px;
    background-color: darkgray;
}
#_zoomPercentage {
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 4px;
    background: rgb(86 88 95 / 73%) !important;
    top: calc(10% - 20px) !important;
    font-size: 18px !important;
}